
.organzaBlushing_Pink {
  background-color: #EDD4DC;
}

.organzaCandy_Pink {
  background-color: #EFBDCF;
}

.organzaPearl_Pink {
  background-color: #DA948A;
}

.organzaWatermelon {
  background-color: #EA95AC;
}

.organzaRuby {
  background-color: #AC3040;
}

.organzaBurgundy {
  background-color: #5C303F;
}

.organzaOrange {
  background-color: #EC8E67;
}

.organzaDaffodil {
  background-color: #E8D197;
}

.organzaWhite {
  background-color: #FFFFFF;
}

.organzaIvory {
  background-color: #FDFAED;
}

.organzaChampagne {
  background-color: #E6C69E;
}

.organzaGold {
  background-color: #B28D68;
}

.organzaBrown {
  background-color: #7B5842;
}

.organzaChocolate {
  background-color: #3D2620;
}

.organzaBlack {
  background-color: #292929;
}

.organzaSilver {
  background-color: #C9C9C9;
}

.organzaDark_Green {
  background-color: #066148;
}

.organzaJade {
  background-color: #04A4A3;
}

.organzaClover {
  background-color: #556C33;
}

.organzaLime_Green {
  background-color: #619048;
}

.organzaSage {
  background-color: #A9C699;
}

.organzaSky_Blue {
  background-color: #70A2CC;
}

.organzaPool {
  background-color: #2295AF;
}

.organzaOcean_Blue {
  background-color: #5981C0;
}

.organzaRoyal_Blue {
  background-color: #3261AF;
}

.organzaInk_Blue {
  background-color: #0C4868;
}

.organzaDark_Navy {
  background-color: #2F355E;
}

.organzaRegency {
  background-color: #3E2C61;
}

.organzaGrape {
  background-color: #5A2364;
}

.organzaFuchsia {
  background-color: #A14784;
}

.organzaLilac {
  background-color: #7E69A2;
}

.organzaLavender {
  background-color: #9690C8;
}
