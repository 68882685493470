//fonts

@font-face {
  font-family: 'playfair_display';
  src: url('#{$font-path}playfair-display/playfairdisplay-regular-webfont.eot');
  src: url('#{$font-path}playfair-display/playfairdisplay-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}playfair-display/playfairdisplay-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}-display/playfairdisplay-regular-webfont.woff') format('woff'),
  url('#{$font-path}playfair-display/playfairdisplay-regular-webfont.ttf') format('truetype'),
  url('#{$font-path}playfair-display/playfairdisplay-regular-webfont.svg#playfair_displayregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'proxima';
  src: url('#{$font-path}proximanova/proximanova-reg-webfont.eot');
  src: url('#{$font-path}proximanova/proximanova-reg-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}proximanova/proximanova-reg-webfont.woff2') format('woff2'),
  url('#{$font-path}proximanova/proximanova-reg-webfont.woff') format('woff'),
  url('#{$font-path}proximanova/proximanova-reg-webfont.ttf') format('truetype'),
  url('#{$font-path}proximanova/proximanova-reg-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans-Regular';
  src: url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.eot');
  src: url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.woff2') format('woff2'),
  url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.woff') format('woff'),
  url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.ttf') format('truetype'),
  url('#{$font-path}NunitoSans-Regular/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans-Bold';
  src: url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.eot');
  src: url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.woff2') format('woff2'),
  url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.woff') format('woff'),
  url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.ttf') format('truetype'),
  url('#{$font-path}NunitoSans-Bold/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "remixicon";
  src: url("#{$font-path}remixicon.woff2?t=1726554717830") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('#{$font-path}icomoon1.woff') format('woff');
}