
.laceBlushing_Pink {
  background-color: #fbe5e7;
}

.laceCandy_Pink {
  background-color: #e67aa2;
}

.lacePearl_Pink {
  background-color: #F0AFAB;
}

.laceWatermelon {
  background-color: #E2535E;
}

.laceRuby {
  background-color: #ce2424;
}

.laceBurgundy {
  background-color: #521921;
}

.laceOrange {
  background-color: #ED714C;
}

.laceDaffodil {
  background-color: #FFFAC0;
}

.laceWhite {
  background-color: #FFFFFF;
}

.laceIvory {
  background-color: #FFFDF7;
}

.laceChampagne {
  background-color: #fcf4e2;
}

.laceGold {
  background-color: #e2c26c;
}

.laceBrown {
  background-color: #624638;
}

.laceChocolate {
  background-color: #41322B;
}

.laceBlack {
  background-color: #171717;
}

.laceSilver {
  background-color: #BFC1C5;
}

.laceDark_Green {
  background-color: #024E40;
}

.laceJade {
  background-color: #589EA0;
}

.laceClover {
  background-color: #87934C;
}

.laceLime_Green {
  background-color: #85cf4e;
}

.laceSage {
  background-color: #C3D7BE;
}

.laceSky_Blue {
  background-color: #94ABCF;
}

.lacePool {
  background-color: #5294d2;
}

.laceOcean_Blue {
  background-color: #375C9E;
}

.laceRoyal_Blue {
  background-color: #2C4593;
}

.laceInk_Blue {
  background-color: #0C4868;
}

.laceDark_Navy {
  background-color: #2F355E;
}

.laceRegency {
  background-color: #371B79;
}

.laceGrape {
  background-color: #5A2364;
}

.laceFuchsia {
  background-color: #bc3977;
}

.laceLilac {
  background-color: #B173C6;
}

.laceLavender {
  background-color: #8D7AC8;
}

.laceOff_White {
  background-color: #EAE4E8;
}

.laceCream {
  background-color: #EAE9CB;
}

.laceAubergine {
  background-color: #793444;
}

.laceWarm_Mocha {
  background-color: #674e34;
}

.laceEmerald {
  background-color: #004721;
}

.laceDusty_Blue {
  background-color: #BFD4D6;
}

.laceMagenta {
  background-color: #691950;
}

.laceVanilla {
  background-color: #E7B689;
}
