.p-address{
  //padding-top: 20px;
  .address-list{
    padding:15px;
    h2 {
      margin-bottom: 20px;
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }
    .shipping {
      height: 38px;
      background-color: #f2f2f2;
      padding: 12px 10px;
      margin-top: 15px;
      h3 {
        font-family: $font-sans-serif;
        font-size: 1.4rem;
        line-height: 14px;
      }
    }
    ul {
      margin-top: 15px;
    }
    li {
      line-height: 1.86;
      margin-bottom: 10px;
      font-size: 1.4rem;
      color: $color-black;
      font-family: $font-sans-serif;
    };
    .line {
      margin-top: 15px;
      margin-bottom: 13px;
    }
    a {
      width: 48%;
      height: 40px;
      background-color: #fff;
      color: $color-black;
      border: 1px solid $color-black;
      vertical-align: middle;
      padding: 10px 0;
      font-size: 1.4rem;
    }
    .edit-btn {
      background-color: $color-main;
      color: #fff;
      border: 0;
    }
    .wide-line {
      margin-top:15px;
      width: 100vw;
      height: 10px;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #f5f5f5;
    }
    .new {
      width: 100%;
      height: 40px;
      background-color: $color-main;
      margin-top: 15px;
      margin-bottom: 10px;
      color: #fff;
      border: 0;
    }
  }

  #address-confirm {
    .modal-content {
      width: 85.3vw;
      height: 100%;
      margin: 0 auto;
      box-shadow: none;
      text-align: center;
      margin: 36vh auto;
      h4{
        margin-top:47px;
        margin-bottom: 44px;
        font-size: 1.4rem;
      }
      .modal-footer {
        border-top: solid 1px #b9b9b9;
        font-size:1.6rem;
        padding: 0;
        line-height: 58px;
        a{
          display: block;
          width: 50%;
          text-align: center;
        }
        .ok {
          color: #3599fc;
          border-right: solid 1px #b9b9b9;
        }
      }
    }
  }

}