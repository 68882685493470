.p-coupon {
  background-color: #fff;
  font-family: $font-sans-serif;

  .main-body {
    padding: 0;
  }

  .page_title {
    padding: 6vw 0;
    font-family: 'playfair_display';
    font-size: 5vw;
    text-align: center;
  }
}

.coupon-list {
  .section {
    position: relative;
    width: 92vw;
    margin: 0 auto 4vw;
    padding: 3vw;
    border: solid 1px #f0f0f0;
    background-color: #f9f9f9;

    .top {
      display: flex;
      border-bottom: 1px dashed #d5d5d5;
      padding-bottom: 2vw;
    }

    .left {
      display: flex;
      align-items: center;
      width: 18vw;
      line-height: 1;
      font-weight: bold;
      color: #ec5555;
      text-align: center;
      text-transform: uppercase;

      .style01 {
        white-space: nowrap;

        .f1,
        .f3 {
          font-size: 3vw;
        }

        .f2 {
          font-size: 10vw;
        }
      }

      .style02 {
        .f1 {
          display: block;
          font-size: 7vw;
        }

        .f2 {
          display: block;
          font-size: 3.5vw;
        }
      }

      .style03 {
        .f1 {
          font-size: 8vw;
        }
      }

      .style04 {
        .f1 {
          font-size: 8vw;
        }

        .f2 {
          font-size: 5.8vw;
        }
      }

      .style05 {
        text-align: left;

        .f1,
        .f3 {
          display: block;
          font-size: 3.2vw;
        }

        .f2 {
          display: block;
          font-size: 7.5vw;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 66vw;
      padding-left: 5vw;

      .desc {
        max-height: 3em;
        overflow: hidden;
        font-size: 4vw;
        line-height: 1.5em;
        font-weight: bold;
      }

      .expired {
        margin-top: 1.5vw;
        font-size: 3.2vw;
        color: #999999;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2vw;

      .usage {
        font-size: 3vw;
        color: #999;
        text-align: center;
      }

      .code-box {
        display: flex;
        align-items: center;

        .code {
          margin-right: 2vw;
          font-size: 3vw;
          color: #999;

          span {
            display: inline-block;
            font-weight: bold;
            color: #111;
          }
        }

        .btn-copy {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12vw;
          height: 6vw;
          border: solid 1px #111;
          font-size: 3vw;
          color: #111;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 29vw;
        height: 8vw;
        background-color: #111;
        font-size: 4vw;
        color: #ffffff;

      }
    }
  }
}