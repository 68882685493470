.p-resetpass {
  padding-top: 15px;
  h2 {
    font-family: $font-serif;
    font-size: 1.8rem;
    text-align: center;
    color: $color-black;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }
  .main-body {
    padding: 0 15px;
    p {
      font-size:1.2rem;
      color: $color-gray2;
      line-height: 1.75;
      margin-bottom: 12px;
    }
    label {
      font-weight: normal;
      margin-bottom: 3px;
    }
    .resetpass-name {
      margin-bottom: 8px;
    }
    input {
      width: 100%;
      height: 36px;
      margin-bottom: 15px;
    }

    .change-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      border: none;
      background-color: $color-main;
      margin-bottom: 70px;
      color: #fff;
    }
    .is-error {
      color: red;
      margin-top: -12px;
      margin-bottom: 10px;
      line-height: 1.42857143;
    }
  }
}