.p-flash-sale {
  .ac-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    height: 40px;
    background-color: #f5edea;
    font-size: 14px;
    color: #111111;
    text-transform: uppercase;
  }
/*  .top-banner-ac {
    position: relative;
    min-height: 100px;

    .h2 {
      position: absolute;
      left: 0;
      bottom: 3px;
      width: 100%;
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      text-decoration: underline;
    }

  }*/

  .ac-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0 15px;

    .end-in {
      font-size: 12px;
      font-weight: bold;
      color: #111;
      text-transform: uppercase;
    }

    .clock {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 0 20px;
      margin-top: 10px;
      background-color: #e9e9e9;
      border-radius: 18px;

      .colon {
        margin: 0 3px;
        font-size: 24px;
        font-weight: bold;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        min-width: 24px;
        padding: 0 5px;
        border-radius: 2px;
        background-color: #363636;
        font-family: Arial;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
      }

      .divider-day {
        margin: 8px 8px 1px;
        font-size: 12px;
      }
    }
  }

  .main-body {
    padding: 0;

    .prod-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .prod-item {
        .price-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: auto;
        }
        .list-price {
          i.icon-sprites {
            display: block;
          }
        }

        .sale-process {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;

          .line {
            position: relative;
            width: 80%;
            height: 6px;
            overflow: hidden;
            border-radius: 3px;
            background-color: #e7e7e7;

            .line-p {
              position: absolute;
              top: 0;
              left: 0;
              height: 6px;
              background: url("/build/sp_images/promotion/2019-black-friday/img_process-bg.png") 0 0 repeat-x;
              background-size: 4px 6px;
            }
          }

          .text {
            flex-shrink: 0;
            width: 60px;
            padding-left: 5px;
            font-size: 12px;
            font-weight: bold;
            color: #111;
          }
        }
      }
    }
  }
}
