@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.p-cart {
  //padding-top: $bodyPaddingTop;
  position: relative;

  .shopping-cart {
    margin-top: 10px;
    h2 {
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }

    .item-num {
      font-size: 1.3rem;
      font-weight: 600;
      height: 38px;
      background-color: #f2f2f2;
      padding: 12px 10px;
      margin-top: 15px;
    }

    .cart-list {

      p.title {
        font-size: 1.4rem;
        text-transform: uppercase;
      }

      .cart-group-list {
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;

        .group-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fef8f0;
          margin-bottom: 20px;
          padding: 10px 15px;
          font-size: 12px;

          .gt-l {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .icon_coupon {
              width: 20px;
              height: 12px;
              margin-right: 15px;
            }

            .text {
              .unmet {
                margin-top: 5px;
                a {
                  text-decoration: underline;
                }
              }
            }
          }

          .gt-r {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .icon_success {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .cart-list-ul {
      }

      .cart-item {
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .product-item{
        display: flex;
      }

      .product-image {
        width: 25%;
      }

      .prod-item-tips {
        margin-bottom: 15px;

        .tips-wrap {
          display: flex;
          align-items: center;
          border-radius: 20px;
          border-bottom-left-radius: 0;
          padding: 10px 15px;
          background-color: #fef0f0;
          font-size: 12px;
          line-height: 18px;

          img {
            display: block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }

          p {
            padding-left: 10px;
          }
        }
      }

      .gift-item {
        padding-top: 18px;
        padding-bottom: 20px;

        .gift-tips {
          position: relative;
          min-height: 40px;
          font-size: 12px;
          padding-left: 43px;

          .bonus {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            margin-right: 15px;
            background: url("#{$img-path}category/icon_mark-gift.png") center no-repeat;
            background-size: contain;
            text-indent: -9999px;
          }

          .link {
            margin-top: 2px;
            font-size: 12px;
            color: #0067ff;
          }
        }

        .gift-item-body {
          margin: 5px auto;

          > li {
            float: left;
          }
        }

        .gift-slide-list {
          display: flex;
          flex-wrap: nowrap;
          overflow: auto;
          margin-top: 10px;

          .gift-slide-item {
            flex: none;
            width: 109px;
            margin-right: 35px;

            .img-wrap {
              display: block;
              width: 109px;
              height: 174px;
              margin: 0 auto;
            }

            img {
              width: 100%;
              height: 100%;
            }

            .gift-name {
              height: 36px;
              overflow: hidden;
              margin-top: 10px;
              font-size: 12px;
              line-height: 18px;
            }

            .gift-price {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              margin-top: 10px;
              padding: 0 5px;
              font-size: 14px;
              color: #000000;

              .del {
                font-size: 12px;
                color: #999999;
                text-decoration: line-through;
              }
            }

            .btn-shopping {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 99px;
              height: 26px;
              margin: 10px auto;
              border: 1px solid rgba(51, 51, 51, 1);
              cursor: pointer;
              font-size: 14px;
              color: #414141;

            }
          }
        }
      }

      .item-tips {
        //display: flex;
        //align-items: center;
        max-width: 100%;
        //height: 34px;
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 6px 10px;
        border: solid 1px #fbd663;
        background-color: #fffae4;
        font-size: 12px;
        color: #de5447;
        line-height: 1.2;

        .gift-item-countdown {
          display: inline-block;
          width: 44px;
          line-height: 1.2;
          overflow: hidden;
          vertical-align: middle;
        }
      }

      .item-chose-gift-btn {
        margin-top: 15px;
        font-size: 14px;
        line-height: 17px;

        a {
          color: #0067FF;
          text-decoration-line: underline;
          text-decoration-color: #CEE2F5;
        }
      }

      .prod-info {
        width: 74%;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-attrs{
          font-size: 12px;
          line-height: 26px;
        }

        .key {
          display: inline-block;
        }
      }

      .p-name {
        width: 80%;
        @include one-line-text;
      }

      .color-block {
        display: inline-block;
        margin-right: 6px;
        margin-left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        vertical-align: middle;
      }

      .size-info-detail {
        font-size: 1.2rem;
        line-height: 24px;
      }

      .size-info-simple {
        .details-indicator {
          width: 10px;
          float: right;
          margin-right: 3px;
          margin-top: 6px;
          transform: rotate(0deg);
          transition: transform 0.3s linear;
        }

        .details-indicator.open {
          transform: rotate(180deg);
          transition: transform 0.3s linear;
        }
      }

      .cart-item-customization-services {
        ul {
          padding-left: 33px;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: normal;
          line-height: 26px;
        }

        input {
          display: block;
          margin-top: -2px;
          margin-right: 6px;
        }
      }

      .cart-item-additional-services {
        display: flex;

        .key {
          display: block;
          margin-right: 5px;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: normal;
          line-height: 26px;
        }

        input {
          display: block;
          margin-top: -2px;
          margin-right: 6px;
        }
      }

      .p-qty {
        .value {
          font-size: 1.4rem;
        }
      }

      .p-price {
        .value {
          font-size: 1.3rem;
          font-weight: bold;
          color: $color-red2;
        }

        .list-price-value{
          margin-left: 10px;
          font-size: 1.2rem;
          color: $color-gray2;
          text-decoration: line-through;
        }
      }

      .tips {
        margin-top: 10px;
        padding: 10px;
        overflow: auto;
        border-radius: 4px;
        background-color: #f9f9f9;
        font-size: 1.2rem;
        resize: none;
        appearance: none;
        width: 100%;
        height: 55px;

        &:focus {
          border-color: $color-black;
        }
      }

      .action {
        display: flex;
        justify-content: space-between;
        .item-del {
          width: 60px;
          height: 20px;
        }
        .p-qty {
          display: flex;

          .minus, .plus {
            align-items: center;
            background: #F2F2F2;
            display: flex;
            height: 20px;
            justify-content: center;
            width: 20px;
            line-height: 17px;
          }
          .value {
            width: 26px;
            text-align: center;
            height:20px;
            line-height: 20px;
          }
        }
      }
      .qty-tips {
        font-size: 12px;
        color: #cacaca;
      }
    }

    /*     .btn-go-shopping {
          display: block;
          margin: 134px auto 111px;
          font-size: 1.2rem;
          color: #999;
          text-align: center;
          text-decoration: underline;
        } */


    .continue {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 1.3rem;
      color: $color-gray2;
      text-decoration-line: underline;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .empty-cart {
      text-align: center;
      padding: 10px;

      .bag {
        width: 96px;
        height: 122px;
        margin: 72px auto 20px auto;

        img {
          width: 100%;
        }
      }

      p {
        font-size: 1.4rem;
        color: $color-gray2;
        margin-bottom: 175px;
      }

      .btn {
      }

      .btn-continue {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 1.6rem;
        margin-top: 15px;
        background-color: #fff;
        color: $color-black;
        border: 1px solid #ccc;
      }
    }
  }

  .bottom-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fcfcfc;
    box-shadow: 0 -3px 6px rgba(150, 150, 150, .2);

    .cart-shipping {
      background: #fbf8f2;
      padding:8px 15px;
      color: #666;
      display: flex;

      .shipping-icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        .icon-fast-shipping {
          font-size:2rem;
        }
      }

      .shipping-info {
        flex: 1;

        .shipping-message {
          font-size: 1.2rem;
          margin-bottom: 5px;
        }

        .progress {
          height:4px;
          margin:0;
          .progress-bar{
            background:#121212
          }
        }
      }

      .price {
        color: #f00;
      }

      a {
        font-weight: normal;
        color: #0067FF;
      }
    }

    .btn-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      border-top: 1px solid #eee;
      position: relative;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46%;
        border: none;
        border-radius: 10px !important;
        font-size: 1.4rem;
        line-height: 41px;
        text-align: center;
      }

      .proceed-btn {
        background-color: $color-main;
        color: #fff;
        text-transform: uppercase;
      }
    }

    .total-tips {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      margin-left: 15px;
      font-size: 13px;
      font-weight: bold;

      .subtotal-value {
        font-size: 16px;
        color: #fa303c;
        text-align: center;
      }

      .subtotal-saved {
        font-size: 12px;
        color: #666;
      }

      .subtotal-container {
        border: 1px solid #eee;
      }

      i {
        font-size: 20px;
        font-weight: normal;
        color: #666;
        padding-left: 3px;
      }

      .icon-arrow-down-s-line {
        font-size: 26px;
        display: block;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transition: transform 0.3s linear;
      }

      .icon-arrow-down-s-line.open {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
      }
    }
  }
}

#cart-delete-confirm,
#confirm {
  .modal-content {
    width: 85.3vw;
    box-shadow: none;
    text-align: center;
    margin: 36vh auto;

    h4 {
      margin-top: 47px;
      margin-bottom: 44px;
      font-size: 1.4rem;
    }

    .modal-footer {
      border-top: solid 1px #b9b9b9;
      font-size: 1.6rem;
      padding: 0;
      line-height: 58px;

      a {
        display: block;
        width: 50%;
        text-align: center;
      }

      .ok {
        color: #3599fc;
        border-right: solid 1px #b9b9b9;
      }
    }
  }
}

.p-name {
  .badge_bonus {
    display: inline-block;
    height: 18px;
    padding: 0 8px;
    margin-right: 8px;
    border-radius: 9px;
    border: 1px solid #ee7474;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ee7474;
  }
}

#ChoseGiftModal {
  .modal-dialog {
    width: 85%;
    margin: 20% auto;
  }

  .modal-content {
    padding: 20px;
    border-radius: 0;
  }

  .close {
    line-height: 2.4;
  }

  h4.title {
    margin: -20px -20px 12px;
    background-color: #f0f0f0;
    font-size: 1.4rem;
    line-height: 48px;
    text-align: center;
  }

  .sub-title {
    font-size: 1.4rem;
    line-height: 21px;
  }

  .p-detail {
    position: relative;
    margin: 17px auto 20px;
    padding: 15px 0;
    border-top: 1px dashed #d9d9d9;
    border-bottom: 1px dashed #d9d9d9;
  }

  .chose-checkbox {
    position: absolute;
    top: 50%;
    margin-top: -6px;
  }

  .img-wrap {
    width: 27%;
    margin-left: 8%;

    img {
      width: 100%;
    }
  }

  .p-info {
    margin-left: 3%;
    width: 62%;
    font-size: 1.2rem;

    .p-name {
      margin-bottom: 10px;
      line-height: 1.4;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .p-price-cost {
      margin-top: 20px;
      text-align: right;
      font-size: 1.4rem;
      color: #CC0000;
    }

    .p-price-list {
      margin-top: 10px;
      text-align: right;
      font-size: 1.2rem;
      color: #999999;
    }
  }

  .btn-checkout {
    display: block;
    width: 100%;
    margin-top: 20px;
    color: #fff;
    background-color: #ee7474;
    border-radius: 2px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
  }
}

.cart-box {
  padding:10px 20px;
}

.subtotal-detail {
  position: absolute;
  bottom: 70px;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;

  .subtotal-header {
    h4 {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding-left: 15px;
      font-weight: bold;
    }
  }

  .summary {
    padding: 10px 15px;
    border-top: 1px solid #e5e5e5;

    .total-list {
      width: 100%;
      border-bottom: 1px solid #eee;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
        font-size: 12px;
      }

      span {
        display: block;
        padding: 3px 0;
        text-align: right;
      }

    }

    .total-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 16px;
      padding-top: 10px;
    }
  }

  .pay-in-4-wrap {
    margin-top: 10px;
    margin-bottom: 25px;
    float: right;
  }
}


.also-like-box {
  width: 100%;
  padding: 20px 0;
  background-color: #f5f5f5;

  .b-title {
    padding: 0 15px;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "sans-serif";
  }

  .b-tips {
    margin: 10px 15px;
    padding: 5px 22px;
    background: #FAF0D5;
    border-radius: 14px;
    border: 1px solid #F39800;
    font-size: 12px;
    color: #F39800;
  }

  .list {
    display: flex;
    width: 100%;
    overflow-x: auto;

    .item {
      flex: none;
      width: 109px;
      margin: 0 15px;

      img {
        width: 109px;
        height: 174px;
      }

      .name {
        margin-top: 9px;
        height: 30px;
        overflow: hidden;
        font-size: 12px;
        line-height: 15px;
      }

      .price-wrap {
        display: flex;
        align-items: center;
        margin-top: 6px;

        input {
          margin: 0 2px;
        }

        .price {
          font-size: 14px;
          color: #FF0200;
        }
      }
    }

    .plus-sign {
      width: 20px;
      height: 20px;
      margin-top: 80px;
    }

    .plus-sign:last-of-type {
      margin-top: 180px;
      display: none;
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 20px;
    padding-right: 15px;
    text-align: right;

    .line2 {
      font-size: 14px;

      .package_total {
        font-weight: bold;
        color: #FF0200;
      }
      .discount{
        font-size: 12px;
      }
    }

    .btn-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      margin-top: 12px;
      background-color: #ee7474;
      border-radius: 6px;
      text-transform: uppercase;
      font-family: "sans-serif";
      font-size: 1.4rem;
      line-height: 40px;
      color: #fff;
    }
  }
}

#MultiGiftModal {
  .modal-dialog {
    position: absolute;
    width: 100%;
    overflow: auto;
    margin: 0;
    top: 0;
    bottom: 0;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    height: 100vh;
    padding-bottom: 50px;
    //padding: 0 15px;
    background-color: #fff;
    overflow: auto;
  }

  .modal-header {
    //margin: 0 -15px;
    padding: 0;
    height: 54px;
    border-bottom: 1px solid #e5e5e5;

    .back {
      display: block;
      position: relative;
      width: 60px;
      height: 54px;

      i {
        display: block;
        position: absolute;
        top: 17px;
        left: 20px;
        background-position: -350px -300px;

      }
    }
  }

  &.fade {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  h2 {
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    font-family: $font-serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    color: $color-black;
    letter-spacing: 2px;
  }

  .btn-shopping {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 26px;
    margin: 10px auto;
    border: 1px solid rgba(51, 51, 51, 1);
    cursor: pointer;
    font-size: 14px;
    color: #414141;
  }
}
