
.chiffonAubergine {
  background-color: #98385A;
}

.chiffonBlack {
  background-color: #2D3129;
}

.chiffonBlush {
  background-color: #F9C6B7;
}

.chiffonBlushing_Pink {
  background-color: #FFE4EC;
}

.chiffonRose_Pink {
  background-color: #FED4E7;
}

.chiffonCashmere {
  background-color: #D6CBC6;
}

.chiffonChampagne {
  background-color: #E3D0A7;
}

.chiffonSmoke {
  background-color: #707579;
}

.chiffonWarm_Mocha {
  background-color: #513226;
}

.chiffonClover {
  background-color: #ADBA58;
}

.chiffonDaffodil {
  background-color: #FFFED8;
}

.chiffonDark_Navy {
  background-color: #212A67;
}

.chiffonEggshell {
  background-color: #F6F1E7;
}

.chiffonEmerald {
  background-color: #047C40;
}

.chiffonCockscomb {
  background-color: #DC39A1;
}

.chiffonGrape {
  background-color: #571F57;
}

.chiffonHot_Raspberry {
  background-color: #FD73B0;
}

.chiffonIce_Mint {
  background-color: #D5FDE8;
}

.chiffonIce_Yellow {
  background-color: #FFFA84;
}

.chiffonInk_Blue {
  background-color: #194C7D;
}

.chiffonIvory {
  background-color: #FFFDF7;
}

.chiffonJamaican_Mist {
  background-color: #8FF6D9;
}

.chiffonLavender {
  background-color: #A084B7;
}

.chiffonDusty_Blue {
  background-color: #D5E6F4;
}

.chiffonLilac {
  background-color: #E7CCF9;
}

.chiffonLime_Green {
  background-color: #DBF549;
}

.chiffonMagenta {
  background-color: #971D78;
}

.chiffonMallard {
  background-color: #055C69;
}

.chiffonRoyal_Blue {
  background-color: #232DB1;
}

.chiffonRuby {
  background-color: #C63132;
}

.chiffonSage {
  background-color: #E2FABC;
}

.chiffonTurquoise {
  background-color: #0FABB1;
}

.chiffonVanilla {
  background-color: #FFE0CA;
}

.chiffonWatermelon {
  background-color: #FE787C;
}

.chiffonWhite {
  background-color: #FFFFFF;
}

.chiffonBrown {
  background-color: #503429;
}

.chiffonBurgundy {
  background-color: #5B242E;
}

.chiffonCandy_Pink {
  background-color: #F8A2BF;
}

.chiffonChocolate {
  background-color: #271C19;
}

.chiffonDark_Green {
  background-color: #2B412D;
}

.chiffonFuchsia {
  background-color: #C83980;
}

.chiffonGold {
  background-color: #DBC086;
}

.chiffonJade {
  background-color: #119797;
}

.chiffonOcean_Blue {
  background-color: #2047A8;
}

.chiffonOrange {
  background-color: #E54F29;
}

.chiffonPearl_Pink {
  background-color: #F5E0D3;
}

.chiffonPool {
  background-color: #1281C8;
}

.chiffonRegency {
  background-color: #1F153A;
}

.chiffonSilver {
  background-color: #B8B9B2;
}

.chiffonSky_Blue {
  background-color: #82A2C6;
}

.chiffonCream {
  background-color: #EAE9CB;
}

.chiffonLight_Sky_Blue {
  background-color: #DFEEFA;
}
