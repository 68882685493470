.p-changepwd {
  .pwd-box {
    padding: 15px;
    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
    h3{
      height:14px;
      font-family: $font-sans-serif;
      font-size: 1.2rem;
      color: $color-black;
      margin-bottom: 3px;
    }
    div {
      margin-bottom: 8px;
      .is-error {
        display: block;
        padding-top: 5px;
      }
    }
    .ipt {
      width: 100%;
      height: 36px;
    }
    .update-btn{
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
      margin-top: 7px;
      margin-bottom: 10px;
      color: #fff;
    }
  }
  #change-ok{
    .modal-content {
      width: 85.3vw;
      height: 100%;
      box-shadow: none;
      margin: 0 auto;
      text-align: center;
      margin: 36vh auto;
      h4{
        margin-top:47px;
        margin-bottom: 44px;
        font-size: 1.4rem;
      }
      .modal-footer {
        border-top: solid 1px #b9b9b9;
        font-size:1.6rem;
        padding: 0;
        line-height: 58px;
        a{
          display: block;
          width: 100%;
          text-align: center;
          color: #3599fc;
        }

      }
    }
  }
}