
.taffetaBlushing_Pink {
  background-color: #EDD4DC;
}

.taffetaCandy_Pink {
  background-color: #EFBDCF;
}

.taffetaPearl_Pink {
  background-color: #F0AFAB;
}

.taffetaWatermelon {
  background-color: #F57D7E;
}

.taffetaRuby {
  background-color: #AE1E1E;
}

.taffetaBurgundy {
  background-color: #57231E;
}

.taffetaOrange {
  background-color: #E58802;
}

.taffetaDaffodil {
  background-color: #FFF7C0;
}

.taffetaWhite {
  background-color: #FFFFFF;
}

.taffetaIvory {
  background-color: #F9FAE5;
}

.taffetaChampagne {
  background-color: #B19A70;
}

.taffetaGold {
  background-color: #B28D68;
}

.taffetaBrown {
  background-color: #624638;
}

.taffetaChocolate {
  background-color: #41322B;
}

.taffetaBlack {
  background-color: #292929;
}

.taffetaSilver {
  background-color: #C9C9C9;
}

.taffetaDark_Green {
  background-color: #0D5421;
}

.taffetaJade {
  background-color: #04A4A3;
}

.taffetaClover {
  background-color: #635F18;
}

.taffetaLime_Green {
  background-color: #87C31F;
}

.taffetaSage {
  background-color: #C3D7BE;
}

.taffetaSky_Blue {
  background-color: #94ABCF;
}

.taffetaPool {
  background-color: #6497CE;
}

.taffetaOcean_Blue {
  background-color: #375C9E;
}

.taffetaRoyal_Blue {
  background-color: #2C4593;
}

.taffetaInk_Blue {
  background-color: #0C4868;
}

.taffetaDark_Navy {
  background-color: #2F355E;
}

.taffetaRegency {
  background-color: #371B79;
}

.taffetaGrape {
  background-color: #5A2364;
}

.taffetaFuchsia {
  background-color: #BC395B;
}

.taffetaLilac {
  background-color: #B173C6;
}

.taffetaLavender {
  background-color: #8D7AC8;
}
