

.animate_click {
    &:active {
        animation: anim_key_scale 0.2s;
    }
}

@keyframes anim_key_scale {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    30% {
        transform: scale(0.97);
        opacity: 0.9;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }

}