//category css
.p-ctg {
  //padding-top: $bodyPaddingTop;
  .ctg-header {
    .title-box {
      padding: 10px 0;
    }

    h1 {
      font-family: $font-serif;
      font-size: 26px;
      color: #333;
      text-transform: capitalize;
    }

    .title-tool {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .count {
        font-size: 14px;
        color: #86868a;
      }

      .btn-view {
        display: flex;
        align-items: center;
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid #999;

        .icon {
          width: 15px;
          height: 15px;
          margin-right: 8px;
          background-image: url(#{$img-path}category/icon_grid-larger.png);
          background-size: contain;
        }

        .text {
          font-size: 14px;
          color: #666;
          text-transform: capitalize;
          text-decoration: underline;
        }

        &.larger {
          .icon {
            background-image: url(#{$img-path}category/icon_grid-small.png);
          }
        }
      }
    }

    .sort-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 39px;
      margin: 0 -15px;
      padding-left: 15px;
      //border-bottom: 1px solid #d8d8d8;
    }

    .sort-box {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .sort-l {
        display: flex;
        justify-content: flex-start;
        width: 60%;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 33px;
        padding-right: 3vw;
        font-size: 1.4rem;
        color: #111;

        &.on {
          font-weight: bold;
          color: $color-red3;
        }
      }

      .sort-r {
        display: flex;
        justify-content: flex-end;
        width: 40%;
        padding-right: 15px;
        font-weight: bold;

        .item {
          width: 100%;
          justify-content: flex-end;
          padding-right: 0;

          &.price {
            .arrow {
              display: block;
              width: 10px;
              height: 10px;
              background-position: -150px -50px;
            }

            &.asc {
              .arrow {
                background-position: -250px -50px;
              }
            }

            &.desc {
              .arrow {
                background-position: -200px -50px;
              }
            }
          }
        }
      }
    }

    .btn-filter {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      padding: 0 15px;
      border-left: 1px solid #d8d8d8;
      font-size: 14px;
      color: #111;
      font-weight: bold;
      text-transform: capitalize;

      .icon-s {
        flex: none;
        width: 20px;
        height: 14px;
        background-position: -350px 0;
      }
    }
  }

  .nav_new_in {
    margin: 0 -15px;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;

    .list {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 13px 15px 7px;
      overflow-x: scroll;

      .item {
        flex: none;
        margin-right: 25px;
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        font-size: 12px;
        color: #999999;

        &.cur {
          color: #ee7474;
          border-color: #ee7474;
        }

        &.empty {
          width: 1px;
        }
      }

    }
  }

  .links-box-under-title {
    margin: 10px -15px;
    user-select: none;

    ul {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: visible;
      -webkit-overflow-scrolling: touch;
    }

    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      background-color: #f6f6f6;
      border-radius: 25px;
      font-size: 1.2rem;
      color: #86868a;
      text-transform: uppercase;
    }

  }

  .prod-list {
    margin-top: 15px;
  }

  .pagination {
    margin-bottom: 0;
  }

  .category_description {
    padding: 15px;
    color: #111;

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      //text-decoration: underline;
    }

    .desc {
      font-size: 12px;
      line-height: 1.5;
      color: #555;

      h3 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      p {
        margin: 10px 0;

        a {
          color: #4781e6;
          text-decoration: underline;
        }
      }
    }
  }

  .recommend-list {
    margin-top: 5px;
  }


}

//product list
.prod-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;

  &.img-square {
    .prod-item {
      .prod-img-wrap {
        aspect-ratio: 1/1;
      }

      .prod-img {
        aspect-ratio: 1/1;
      }
    }
  }

  .prod-item {
    position: relative;
    width: 50%;
    padding: 0 3px;
    margin-bottom: 25px;

    a {
      display: block;
    }
  }

  .prod-img-wrap {
    position: relative;
    width: 100%;

    .prod-img {
      width: 100%;
      aspect-ratio: 366/585;
    }
  }


  .prod-name {
    margin-top: 10px;
    height: 1.3em;
    font-size: 1.3rem;
    line-height: 1.3em;
    color: #86868a;
    text-transform: capitalize;
    overflow: hidden;
    @include one-line-text;
  }

  .bottom-box {
    margin-top: 5px;
  }

  .price-box {
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 5px;
    padding-right: 8px;
  }

  .prod-price, .not-for-sale {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    line-height: 1;
    text-transform: uppercase;

    .price-symbol {
      font-size: 1.2rem;
      color: #666;
      font-weight: normal;
    }

    .price-value {
      font-size: 1.6rem;
      font-weight: bold;
      color: $color-red2;
    }
  }

  .market-price {
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    color: #999;
    text-decoration: line-through;
    text-transform: uppercase;
  }

  .p-discount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
    height: 20px;

    .text {
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .like-box {
      display: flex;
      align-items: center;

      .count {
        color: #86868a;
      }

      .liked + .count {
        color: #111;
      }
    }
  }


  .icon-like {
    display: block;
    width: 25px;
    height: 20px;
    background-position: -350px -100px;

    &.liked {
      background-position: -300px -100px;
    }
  }

  .mark-icon-box-bottom {
    position: absolute;
    bottom: 0;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 18px;
    pointer-events: none;

    .mark-item {
      height: 18px;

      &.free_shipping {
        width: 94px;
        background: url(#{$img-path}category/mark-icon_free_shipping.png) 0 0 no-repeat;
        background-size: cover
      }

      &.hrs48 {
        width: 56px;
        background: url(#{$img-path}category/mark-ico_hrs48.png) 0 0 no-repeat;
        background-size: cover
      }

      &.hrs72 {
        width: 56px;
        background: url(#{$img-path}category/mark-ico_hrs72.png) 0 0 no-repeat;
        background-size: cover
      }
    }



  }

  .mark-icon-box {
    position: absolute;
    top: 15px;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 16px;
    padding: 0 3px;
    pointer-events: none;
    //opacity: .8;


    .mark-item {
      height: 16px;
      background: url(#{$img-path}category/img_mark-icon.png) 0 0 no-repeat;
      background-size: 350px 50px;

      &.sale {
        width: 32px;
        background-position: 0 0;
      }

      &.new {
        width: 32px;
        background-position: -50px 0;
      }

      &.hot {
        width: 28px;
        background-position: -100px 0;
      }

      &.flash {
        width: 66px;
        background-position: -150px 0;
      }

      &.limited {
        width: 72px;
        background-position: -250px 0;
      }

      &.off {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 21px;
        background: none;
        background-image: linear-gradient(293deg, #fdaa8b, #ff546f);
        //font-family: 'NunitoSans';
        font-size: 12px;
        color: #fff;

        span {
          font-size: 14px;
        }
      }
    }
  }

  // larger view.
  &.larger {
    .prod-item {
      width: 100%;
      padding: 5px 10px;
    }

    .prod-name {
    }

    .bottom-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .prod-price {
      margin-right: 20px;

      .price-value {
        font-size: 20px;
      }
    }

    .p-discount {
      justify-content: flex-start;

      .like-box {
        margin-left: 30px;
      }
    }
  }
}

//pagination
/*.pagination {
  position: relative;
  padding: 0 15px;
  width: 100%;
  .arrow {
    display: block;
    position: absolute;
    top: 0;
    width: 38px;
    height: 38px;
    background-color: $color-main;
    i {
      width: 8px;
      height: 11px;
      margin-top: 13px;
      margin-left: 16px;
    }
    &.prev {
      i {
        background-position: -200px 0;
      }
    }
    &.next {
      i {
        background-position: -150px 0;
      }
    }
    &.disabled {
      background-color: $color-gray4;
      &.prev {
        i {
          background-position: -250px -300px;
        }
      }
      &.next {
        i {
          background-position: -200px -300px;
        }
      }
    }
  }
  .prev {
    left: 15px;
  }
  .next{
    right: 15px;
  }
  ul{
    margin:0 55px;
    text-align: center;
    li{
      display: inline-block;
      margin:0 4%;
      font-size: 1.2rem;
      line-height: 38px;
      color: #111;
      span {
        color: $color-gray2;
      }
      &.cur {
        a{
          text-decoration:underline;
        }
      }
    }
  }
}*/
.pagination {

  //variable
  $paginationHeight: 40px;

  position: relative;
  padding: 0 15px;
  width: 100%;
  overflow: hidden;

  .btn {
    display: block;
    float: left;
    width: 35%;
    height: $paginationHeight;
    border-radius: 2px;
    background-color: $color-main;
    font-size: 17px;
    color: #fff;
    line-height: $paginationHeight;
    text-align: center;
    text-transform: capitalize;

    &.disabled {
      background-color: #ddd;
      color: #666;
      visibility: hidden;
    }
  }

  .cur-page {
    display: block;
    float: left;
    width: 30%;
    overflow: hidden;
    font-size: 18px;
    //font-weight: bold;
    line-height: $paginationHeight;
    text-align: center;

  }

}

#filter-modal {
  .modal-dialog {
    position: absolute;
    width: 100%;
    height: 85vh;
    overflow: auto;
    margin: 0;
    bottom: 0;
    border-top: 1px solid #eee;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    background-color: #fff;
  }

  .modal-content {
    position: relative;
    border-radius: 0;
    border: none;
    padding: 40px 15px;
    height: 100%;
    overflow: auto;
  }

  &.fade {
    .modal-dialog {
      transform: translate(0, 100%);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  .close {
    position: absolute;
    top: 22px;
    right: 22px;
    background-position: 0 -50px;
    opacity: 1;
  }

  .m-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 20px 11px 20px;
    background-color: #fff;

    .tools {
      display: flex;
      justify-content: space-between;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46%;
        height: 36px;
        font-size: 14px;
        font-weight: bold;
      }

      .btn-clear {
        border: 1px solid #111;
      }

      .btn-apply {
        color: #fff;
        background-color: $color-red3;
      }
    }
  }

  .filter-box {
    margin-left: -20px;
    margin-right: -20px;
    user-select: none;

    ul {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 20px;
      overflow-x: scroll;
      overflow-y: visible;
    }

    li {
      flex: none;
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 1;
      color: #111;

      &:last-of-type {
        &:after {
          content: ' ';
          display: block;
          width: 20px;
          height: 1px;
        }
      }
    }

    .btn-close {
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background-position: -50px -50px;
    }
  }

  .content-wrap {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding-bottom: 80px;
  }

  .content {
    padding-bottom: 100px;

    .item1 {
      padding: 20px;

      &:first-child {
        padding-top: 0;
      }
    }

    .f-title {
      font-size: 14px;
      font-weight: bold;
      color: #111;
    }

    .flt-2 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
    }

    .item2 {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      margin-top: 6px;
      margin-right: 6px;
      padding: 0 14px;
      border: 1px solid #e9e9e9;
      border-radius: 26px;
      font-size: 1.2rem;
      line-height: 1em;

      &.selected {
        border-color: #111;
      }
    }

    .color-block {
      display: flex;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 50%;
    }

  }

}

.ad-in-list {
  overflow: hidden;

  .ad-wrap {
    display: block;
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100%;
      height: auto !important;
      aspect-ratio: 0;
    }
  }

  //.info p {
  //  height: 1.2rem;
  //  margin-top: 5px;
  //  margin-bottom: 35px;
  //}
}
