.cart-item-list {

  p.title {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  ul {
    margin: 5px 0;
    border-bottom: 3px solid #eee;
  }

  li {
    overflow: hidden;
    padding: 10px 0;

    .product-image {
      float: left;
      width: 25%;

      a {
        display: block;
      }
    }

    & + li {
      border-top: 1px solid #eee;
    }
  }

  .prod-info {
    float: left;
    width: 74%;
    padding-left: 17px;
    font-size: 12px;
    line-height: 1.6em;

    .key {
      display: inline-block;
    }

    .size {
      margin-right: 6px;
    }
  }

  .p-name {
    width: 70%;
    @include one-line-text;
  }

  .color-block {
    display: inline-block;
    margin-right: 6px;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    vertical-align: middle;
  }

  .size-info {
    padding-left: 38px;
  }

  .size-info-simple {
    .details-indicator {
      width: 10px;
      float: right;
      margin-right: 3px;
      margin-top: 6px;
      transform: rotate(0deg);
      transition: transform 0.3s linear;
    }

    .details-indicator.open {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
    }
  }

  .services {
    line-height: 1.6rem;
    font-size: 12px;

    ul {
      padding: 0 0 0 5px;
      border: none;
      margin: 0;
    }

    li {
      margin: 0;
      padding: 0;
    }

    .key {
      display: block;
      margin-right: 5px;
    }
  }

  .p-qty {
    .value {
      margin-left: 13px;
      font-size: 1.4rem;
      line-height: 2em;
    }
  }

  .p-price {
    .value {
      font-size: 1.4rem;
      font-weight: normal;
      color: $color-red2;
      margin-left: 8px;
    }

    .status {
      margin-left: 10px;
      font-size: 1.4rem;
      color: #666;
    }

    &.cancel {
      .value {
        font-size: 1.4rem;
        font-weight: normal;
        color: #999;
        text-decoration: line-through;
      }
    }
  }

  .total-list {
    padding-top: 5px;

    ul {
      border-bottom: none;
    }

    li {
      margin-bottom: 6px;
      padding: 0;
      font-size: 1.4rem;
      border: none;
      line-height: 1.71;

      .del {
        display: inline-block;
        margin-right: 5px;
        color: #999;
        text-decoration: line-through;
      }
    }

    li:last-child {
      margin-bottom: 40px;
    }

    .subtotal {
      color: $color-red2;
    }

    .grandtotal {
      font-weight: 600;
      font-size: 1.6rem;
      color: $color-red2;
    }
  }

}