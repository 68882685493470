
.satinBlushing_Pink {
  background-color: #fbe5e7;
}

.satinCandy_Pink {
  background-color: #e67aa2;
}

.satinPearl_Pink {
  background-color: #F0AFAB;
}

.satinWatermelon {
  background-color: #E2535E;
}

.satinRuby {
  background-color: #ce2424;
}

.satinBurgundy {
  background-color: #521921;
}

.satinOrange {
  background-color: #ED714C;
}

.satinDaffodil {
  background-color: #FFFAC0;
}

.satinWhite {
  background-color: #FFFFFF;
}

.satinIvory {
  background-color: #FFFDF7;
}

.satinChampagne {
  background-color: #fcf4e2;
}

.satinGold {
  background-color: #e2c26c;
}

.satinBrown {
  background-color: #624638;
}

.satinChocolate {
  background-color: #41322B;
}

.satinBlack {
  background-color: #171717;
}

.satinSilver {
  background-color: #BFC1C5;
}

.satinDark_Green {
  background-color: #024E40;
}

.satinJade {
  background-color: #589EA0;
}

.satinClover {
  background-color: #87934C;
}

.satinLime_Green {
  background-color: #85cf4e;
}

.satinSage {
  background-color: #C3D7BE;
}

.satinSky_Blue {
  background-color: #94ABCF;
}

.satinPool {
  background-color: #5294d2;
}

.satinOcean_Blue {
  background-color: #375C9E;
}

.satinRoyal_Blue {
  background-color: #2C4593;
}

.satinInk_Blue {
  background-color: #0C4868;
}

.satinDark_Navy {
  background-color: #2F355E;
}

.satinRegency {
  background-color: #371B79;
}

.satinGrape {
  background-color: #5A2364;
}

.satinFuchsia {
  background-color: #bc3977;
}

.satinLilac {
  background-color: #B173C6;
}

.satinLavender {
  background-color: #8D7AC8;
}

.satinOff_White {
  background-color: #EAE4E8;
}

.satinCream {
  background-color: #EAE9CB;
}

.satinAubergine {
  background-color: #793444;
}

.satinWarm_Mocha {
  background-color: #674e34;
}

.satinEmerald {
  background-color: #004721;
}

.satinDusty_Blue {
  background-color: #BFD4D6;
}

.satinMagenta {
  background-color: #691950;
}

.satinVanilla {
  background-color: #E7B689;
}

.satinLight_Sky_Blue {
  background-color: #DFEEFA;
}