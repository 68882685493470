.icomoon {
    font-family: "icomoon" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-link-circle:before {
    content: "\e913";
}
.icon-imessage .path1:before {
    content: "\e910";
    color: rgb(87, 187, 99);
}
.icon-imessage .path2:before {
    content: "\e911";
    margin-left: -1em;
    color: rgb(254, 254, 254);
}
.icon-whatsapp .path1:before {
    content: "\e917";
    color: rgb(77, 194, 71);
}
.icon-whatsapp .path2:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-fast-shipping:before {
    content: "\e902";
}
.icon-pinterest:before {
    content: "\e912";
}
.icon-facebook:before {
    content: "\e914";
}
.icon-twitter-alt-circle:before {
    content: "\e916";
}
.icon-shopping-bag-4-line:before {
    content: "\e903";
}
.icon-ruler-line:before {
    content: "\e904";
}
.icon-palette-line:before {
    content: "\e905";
}
.icon-logout-box-r-line:before {
    content: "\e906";
}
.icon-user-line:before {
    content: "\e907";
}
.icon-menu-line:before {
    content: "\e908";
}
.icon-star-half-line:before {
    content: "\e909";
}
.icon-star-fill:before {
    content: "\e90a";
}
.icon-star-line:before {
    content: "\e90b";
}
.icon-heart-3-line:before {
    content: "\e90c";
}
.icon-share-2-line:before {
    content: "\e90d";
}
.icon-search-line:before {
    content: "\e90e";
}
.icon-arrow-down-s-line:before {
    content: "\e90f";
}
.icon-question-fill:before {
    content: "\e901";
}
.icon-question-line:before {
    content: "\e900";
}
.icon-mail4:before {
    content: "\ea86";
}