
/*default*/
.Blushing_Pink {
  background-color: #feeaed;
}

.Candy_Pink {
  background-color: #fcd9e8;
}

.Pearl_Pink {
  background-color: #fde6dd;
}

.Watermelon {
  background-color: #fc9798;
}

.Red {
  background-color: #dd122b;
}

.Burgundy {
  background-color: #87122b;
}

.Orange {
  background-color: #fd956a;
}

.Daffodil {
  background-color: #fdf4cc;
}

.White {
  background-color: #fff;
}

.Ivory {
  background-color: #fefefe;
}

.Champagne {
  background-color: #ebd9c3;
}

.Gold {
  background-color: #efcc93;
}

.Brown {
  background-color: #8f6b53;
}

.Chocolate {
  background-color: #503228;
}

.Black {
  background-color: #000;
}

.Silver {
  background-color: #d0d0d3;
}

.Jade {
  background-color: #048ea2;
}

.Clover {
  background-color: #a4b95e;
}

.Lime_Green {
  background-color: #b7e769;
}

.Sage {
  background-color: #e1edc4;
}

.Sky_Blue {
  background-color: #cce9f7;
}

.Pool {
  background-color: #69d0ec;
}

.Ocean_Blue {
  background-color: #34a8e9;
}

.Royal_Blue {
  background-color: #243bad;
}

.Ink_Navy {
  background-color: #275d78;
}

.Dark_Navy {
  background-color: #2a3646;
}

.Regency {
  background-color: #492b85;
}

.Grape {
  background-color: #6b3568;
}

.Fuchsia {
  background-color: #c6388d;
}

.Lilac {
  background-color: #d9caef;
}

.Lavender {
  background-color: #c8d0ef;
}

.Dark_Green {
  background-color: #114542;
}

.Dusty_Rose {
  background-color: #e9bdbb;
}

.Steel_Grey {
  background-color: #6f7478;
}

.Spa {
  background-color: #82dbce;
}

.Wisteria {
  background-color: #c3a5bb;
}

.Mint_Green {
  background-color: #d5f7df;
}

.Turquoise {
  background-color: #9ae4d0;
}

.Orchid {
  background-color: #8e396f;
}

.Taupe {
  background-color: #d1bfb7;
}

.Stormy {
  background-color: #636784;
}

.Mulberry {
  background-color: #843550;
}

.Dusk {
  background-color: #c9aeb8;
}

.Peacock {
  background-color: #18595b;
}

.Mist {
  background-color: #d1e9ee;
}

.Papaya {
  background-color: #f18375;
}

.Tahiti {
  background-color: #9e81b6;
}

.Coral {
  background-color: #feb0a6;
}

.Vermilion {
  background-color: #a6423e;
}

.Celadon {
  background-color: #cbdcc4;
}

.Cream {
  background-color: #EAE9CB;
}

.Claret {
  background-color: #5c2c35;
}

.Steel_Blue {
  background-color: #8c9dad;
}

.Mauve {
  background-color: #cd9ab7;
}

// background-color: #fcdadc
.Blush {
  background-color: #f8c3c6;
}

.Slate_Blue {
  background-color: #798cad;
}

.Petal {
  background-color: #fce3e8;
}

.Plum {
  background-color: #4f354e;
}

.Vintage_Mauve {
  background-color: #dab9be;
}

.Multi  {
  background: linear-gradient(to right, #aa3534 33.33%, #fef984 33.33%, #fef984 66.66%, #212eb1 66.66%, #212eb1 99.99%);
}

.Ombre  {
  background: linear-gradient(360deg, #212eb1, #90f5da);
}

.Two_Tone  {
  background: linear-gradient(180deg, #fff0f0 50%, #f9c4b6 50%);
}

[class*='White'],
[class*='Cream'],
[class*='Ivory'],
[class*='Daffodil'] {
  box-shadow: 0 0 2px #c7c7c7 inset;
}

.As_picture,.As_Picture,.As_Shown {
  background: url(#{$img-path}product/ico_aspicture.png) center no-repeat;
  background-size: cover;
}