.p-repwdemail {
  padding-top: 15px;
  h2 {
    font-family: $font-serif;
    font-size: 1.8rem;
    text-align: center;
    color: $color-black;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }
  .send-email {
    padding: 0 15px;
    p {
      font-size:1.2rem;
      color: $color-gray2;
      line-height: 1.75;
      margin-bottom: 12px;
    }
    label {
      font-weight: normal;
      margin-bottom: 3px;
    }
    input {
      width: 100%;
      height: 36px;
      padding-left: 10px;
      appearance: none;
      border: 1px solid #ccc;
      &:focus {
        border-color: $color-black;
      }

    }

    .change-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      border: none;
      background-color: $color-main;
      margin-top: 18px;
      margin-bottom: 70px;
      color: #fff;
    }
    .warn {
      color: red;
    }
  }
  .send-success {
    padding: 0 15px;
    .icon {
      height: 86px;
      width: 86px;
      background-color: #46ae65;
      margin: 15px auto 20px auto;
      border-radius: 50%;
      padding: 24px;
      i {
        margin: auto;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        background-position: -150px -250px;
      }
    }
    p {
      font-size:1.2rem;
      line-height: 1.75;
    }
    .back-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      border: none;
      background-color: $color-main;
      margin-top: 18px;
      margin-bottom: 70px;
      color: #fff;
    }
  }
}