// base css
.bd-red{
  border: #f00 solid 1px !important;
}

.is-error{
  font-size: 1.4rem;
  color: #f00;
}

.is-success{
  font-size:1.2rem;
  color : #46ae65;
}

//font
html,body{
  font-size: 10px;
  font-family:$font-sans-serif;
  color: #111;
  touch-action: manipulation;
  //image-rendering: -webkit-optimize-contrast;
}

//
.main-body{
  width: 100%;
  padding:0 15px;
}

.full-body{
  width:100%;
}

ol,ul,li{
  margin:0;
  padding:0;
  list-style-type: none;
}

a,
a:hover,
a:focus{
  text-decoration: none;
  outline:none;
  color: inherit;
}

input:focus,
textarea:focus {
  outline:none !important;
}


//image
.img-wrap {
  display: block;
  width: 100%;
}
img {
  max-width:100%;
  pointer-events: none;
  user-select:none;
}

.icon-s {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("#{$icon-sprites}");
  background-repeat:no-repeat;
  background-size: 400px 700px;
  &:active{
    background-image: url("#{$icon-sprites}");
  }
}

$side-icon-width: 44px;
$side-icon-height: 44px;
.icon-top {
  display: block;
  position: fixed;
  background: url(#{$img-path}global/icon_top.png?v=210725) no-repeat;
  background-size: $side-icon-width $side-icon-height;
  width: $side-icon-width;
  height: $side-icon-height;
  bottom:70px;
  right: 6px;
  z-index: 2;
  opacity: .8;
}
.btn-service {
  background: url(#{$img-path}global/icon_service.png?v=210725) no-repeat;
  background-size: $side-icon-width $side-icon-height;
  @extend .icon-top;
  bottom: 120px;
}
.btn{
  border-radius:0;
  padding:0;
  &:hover{
    opacity: .8;
  }
}

.show-table{
  display:table;
  width:100%;
  height:100%;
}

.show-cell{
  display: table-cell;
  vertical-align:middle;
}



//rating:product
.rating-box{
  margin-top:5px;
  i{
    display: inline-block;
    width:100px;
    height: 16px;
    margin-right:5px;
    background-position: -400px -100px;
    vertical-align: middle;
  }
  .avg-rating {
    font-size:12px;
  }
  &.rating-0{
    i{
      background-position: -500px -100px;
    }
  }
  &.rating-1{
    i{
      background-position: -480px -100px;
    }
  }
  &.rating-2{
    i{
      background-position: -460px -100px;
    }
  }
  &.rating-3{
    i{
      background-position: -440px -100px;
    }
  }
  &.rating-4{
    i{
      background-position: -420px -100px;
    }
  }
  &.rating-5{
    i{
      background-position: -400px -100px;
    }
  }
}

//common
//add border for color icon.
li[data-value=White] > a,
li[data-value="Off White"] > a,
li[data-value=Daffodil] > a,
li[data-value=Ivory] > a {
  border:1px solid #d2d2d2;
}

#alert {
  .modal-content {
    width: 85.3vw;
    height: 100%;
    box-shadow: none;
    margin: 0 auto;
    text-align: center;
    margin: 36vh auto;
    h4{
      margin-top:47px;
      margin-bottom: 44px;
      padding-left: 10%;
      padding-right: 10%;
      font-size: 1.4rem;
    }
    .modal-footer {
      border-top: solid 1px #b9b9b9;
      font-size:1.6rem;
      padding: 0;
      line-height: 58px;
      a{
        display: block;
        width: 100%;
        text-align: center;
        color: #3599fc;
      }

    }
  }
}

#successModal{
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 202px;
    height: 58px;
    margin: 0 auto;
    border-radius: 6px;
    border: solid 1px #e7e7e7;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: none;
    font-size: 14px;
    img {
      display: block;
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}



//Line
.line {
  display: block;
  height: 0;
  border-top:1px solid #e5e5e5;
}

h1,h2,h3,h4,h5,p{
  margin: 0;
}

select {
  appearance:none;
}

input[type="text"],
input[type="password"],
select {
  width:100%;
  height: 30px;
  padding-left:10px;
  border: 1px solid #ccc;
  border-radius: 0;
  font-size:1.2rem;
  outline:none;
  -webkit-appearance: none;
  &:focus{
    border-color: $color-black;
  }
}

.ui-select {
  padding-right: 10px;
  background: url("#{$img-path}global/icon_arrow-bottom.png") no-repeat right;
  background-origin: content-box;
  background-size: 14px 9px;
}

.form-control {
  border-radius: 2px;
  &:focus {
    border-color: $color-red1;
    box-shadow: none;
  }
}

.no-outline {
  input:focus{
    outline: none;
  }
}
//flexslide
ol.flex-control-nav {
  position: absolute;
  bottom: 10px;
}

.flex-control-paging li a {
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 1px solid #fff;
  &.flex-active {
    background-color: $color-red1;
    border: 1px solid $color-red1;
  }
}

//swiper
.swiper-pagination-bullet-active {
  background-color: $color-red1;
}

// live-chat hide
.p-live-chat-hide{
  .btn-service {
    display: none !important;
  }
}


#livechat-compact-container {
  display: none !important;
}


// img wisp
.img-wisp {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    float: left;
  }
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    z-index: 1;
  }
  &:after {
    content: '';
    display: table;
    clear:both;
  }
  &.img-wisp-8 {
    img {
      width: 12.5%;
    }
  }
  &.img-wisp-10 {
    img {
      width: 10%;
    }
  }
}

.producttags-box {
  .tags-title {
    display: flex;
    flex-wrap: wrap;
    a {
      display: block;
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
  .tags-list {
    li {
      margin-bottom: 8px;
      font-size: 14px;

    }
  }
}


.new-user-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 46px;
  padding: 0 15px;
  background-color: rgba(254,244,232,.8);
  .left-n {
    display: flex;
    align-items: center;
    .btn-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 1px solid #999;
      background-color: rgba(255,255,255,.8);
      border-radius: 50%;
      opacity: .7;
      i {
        width: 13px;
        height: 13px;
        transform: rotate(45deg);
        background-position: -250px 0;
      }
    }
    .text {
      margin-left: 10px;
      font-size: 14px;
      color: #111111;
      .em {
        color: #fa303c;
      }
    }
  }
  .btn-reg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #111;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

// .modal-backdrop {
//   background-color: #fff;
// }

.loading-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    background: #aaa;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
  }

  .p1 {
    animation: fall 1s linear 0.3s infinite;
  }

  .p2 {
    animation: fall 1s linear 0.2s infinite;
  }

  .p3 {
    animation: fall 1s linear 0.1s infinite;
  }

  .p4 {
    animation: fall 1s linear infinite;
  }
  @keyframes fall {
    0% {
      transform: translateY(-15px);
    }
    25%,
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-15px);
    }
  }
}

.pay-in-4-wrap  {
  margin-top: 20px;
  margin-bottom: 5px;
  img {
      display: inline-block;
      height: 16px;
      vertical-align: middle;
  }
  span {
      font-size: 12px;
      line-height: 16px;
  }
}
