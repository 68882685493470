.p-payment {
  background: #f2f2f2;

  .divide-block{
    height: 10px;
  }

  .container {
    background: #fff;
    padding: 0 10px;

    input,
    select {
      height: 36px;
    }

    h3.title {
      padding: 15px 10px;
      font-size: 16px;
      line-height: 1;
      color: #111;
      font-weight: bold;
      font-family: sans-serif;

      .money {
        color: $color-red2;
      }
    }
  }

  .order-total {
    margin-top:10px;
  }

  .payment-list {
    width: 100%;

    dl {
      border: 1px solid #ccc;
      margin:10px auto;

      dt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 12px;
        min-height: 55px;
        position: relative;

        label {
          display: flex;
          vertical-align: middle;
          margin-bottom: 0;
        }

        span.text {
          margin-left: 8px;
          font-weight: bold;
          vertical-align: middle;
          line-height: 45px;
          font-size: 12px;
          font-family: sans-serif;
        }

        img {
          width: 70px;
          vertical-align: middle;
        }
      }

      dd {
        padding:0 10px;
        p {
          font-size: 12px;
        }
      }
    }

    .card-list {
      overflow: hidden;

      li {
        margin-bottom: 10px;
        overflow: hidden;
        padding: 8px 0;
        border: 1px solid transparent;
        text-align: center;

        img {
          height: 36px;
        }
      }
    }

    input {
      height: 36px;
    }

    @at-root #payment-card {
      vertical-align: middle;
    }
  }

  .btn-payment {
    display: block;
    margin: 10px 0;
    background-color: $color-main;
    font-size: 16px;
    line-height: 45px;
    color: #fff;
    text-align: center;

    &.disabled {
      font-weight: bold;
      background-color: #eee;
      color: #111;
    }
  }

  .no-dollar-tips {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 1.67;
    color: #999999;
  }

  .safety-box {
    background: #fff;
    padding: 20px 10px;

    .lock {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #00b15d;

      img {
        margin-right: 10px;
        width: 31px;
        height: 31px;
      }
    }

    .safe_img {
      margin-top: 15px;
    }
  }

  .icon-top {
    display: none;
  }

  .billing-address {
    margin-top: 0;
    border-top: 1px solid #efefef;
    padding-top: 15px;

    h4{
      font-size:12px;
      padding-bottom: 0;
      padding-left: 0;
      font-family: sans-serif;
    }

    label {
      display: flex;
      justify-content: flex-start;
      vertical-align: middle;
      align-items: center;
      margin-bottom:0;

      input {
        margin-right: 8px;
      }
      span{
        line-height: 28px;
        height: 24px;
      }
    }

    .selected-address{
    }

    .address-show{
      background: #f6f6f6;
      padding: 10px;
      margin: 0;
      border: 1px solid #efefef;

      .address-edit {
        font-size: 16px;
      }
    }
  }
}

#paypal-rest-button-container {
  .error-box {
    color: red;
    font-size: 1.4rem;
    line-height: 26px;
    height: 26px;
  }
}