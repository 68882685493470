.p-reward {
  .main-body {
    padding: 0;
  }

  .s-1 {
    padding: 40px 0 30px 0;
    background: #ffeee7 url("#{$img-path}reward/img_bg.png") bottom no-repeat;
    background-size: contain;
    text-align: center;
    .line1 {
      font-size: 30px;
      font-weight: bold;
    }
    .line2 {
      font-size: 12px;
    }
    .line3 {
      margin-top: 15px;
      img {
        width: 136px;
        height: 50px;
      }
    }
    .line4 {
      width: 276px;
      margin: 17px auto 0;
      font-size: 12px;
      line-height: 18px;
    }

    .code-box {
      width: 290px;
      height: 170px;
      margin: 27px auto 0;
      background: url("#{$img-path}reward/img_code@2x.png");
      background-size: contain;
      overflow: hidden;

      .c-l-1 {
        margin-top: 30px;
        font-size: 14px;
        font-weight: bold;
      }
      .c-l-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        margin-top: 5px;
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .btn-copy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 73px;
        height: 36px;
        margin: 10px auto 0;
        border: solid 2px #111111;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .login-tips {
        margin-top: 19px;
        font-size: 14px;

      }
      .link-login {
        display: inline-block;
        margin-left: 10px;
        color: #0067cc;
      }
    }

  }

  .s-2 {
    padding: 40px 0 50px;
    background-color: #ffeee7;
    .s-inner {
      width: 290px;
      margin: 0 auto;
    }
    .line1 {
      font-size: 14px;
      font-weight: bold;
    }
    .email-ipt {
      width: 100%;
      height: 48px;
      margin-top: 10px;
      border: 1px solid #111;
    }
    .btn-send {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 290px;
      height: 40px;
      margin: 15px 0 0;
      background-color: #ee7474;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
    }

    .line4 {
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .share-group {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      .btn-share {
        width: 60px;
        text-align: center;
        img {
          width: 30px;
          height: 30px;
        }
        .text {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
  
  .s-3 {
    background-color: #f9e1d9;
    padding: 30px 15px;

    .line1 {
      font-size: 18px;
      font-weight: bold;
    }
    .balance-total {
      margin-top: 5px;
      font-size: 30px;
      font-weight: bold;
    }

    .line3 {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }

    .record-list {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        border-bottom: 1px dashed #111;
        font-size: 14px;
        &.th {
          font-weight: bold;
          border-bottom: 1px solid #111;
        }
        &.s-complete {
          .td3 {
            color: #00b15d;
          }
        }
        &.s-processing {
          .td3 {

          }
        }
        &.s-cancelled {
          .td3 {
            color: #86868a;
          }
        }
        div {
          flex:none;
        }
        .td1,.td2 {
          width: 35%;
          margin-right: 3%;
          @include one-line-text;
        }
        .td3 {
          width: 24%;
          font-weight: bold;
        }
      }
    }


  }



}
