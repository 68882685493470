.p-myhistory{
  //padding-top: $bodyPaddingTop;
  .my-history{
    padding: 15px;
    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }
  }

  .btn-remove {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 15px 0;
    font-size: 12px;
    color: #999999;
    i {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      background-position: -150px -650px;
    }
  }

  .his_date {
    padding: 10px 15px;
    font-size: 14px;
    color: #999999;
  }

  .prod-list {
    .prod-item {
      .price-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
      }
    }
  }

  .empty-history {
    padding: 0 15px;
    text-align: center;
    .heart{
      width:64px;
      height: 57px;
      margin: 72px auto 20px auto;
      img {
        width: 100%;
      }
    }
    p{
      font-size:1.4rem;
      color: $color-gray2;
      margin-bottom: 175px;
    }
    .btn-continue{
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      margin-top: 15px;
      background-color: #fff;
      color: $color-black;
      border: 1px solid $color-black;
    }
  }

}