//mixin

@mixin one-line-text{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

@mixin multi-line($line:2){
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}