﻿//referral

//-----------------------

.p-refer {
  h2.title-p {
    padding-top: 15px;
    font-family: $font-serif;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
    color: $color-black;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  .ref-box {
    .val-box {
      position: relative;
      width: 345px;
      height: 145px;
      margin:0 auto;
      background: url(#{$img-path}global/img_points.png) no-repeat;
      background-size: contain;
      .avil {
        margin-left:69px;
        margin-top: 30px;
        .count {
          font-size:24px;
          color: #111;
        }
      }
      .held {
        margin-right: 69px;
        margin-top: 30px;
        .count {
          font-size: 14px;
        }
      }
      .count {
        font-family:$font-sans-serif-base;
        font-weight: bold;
        line-height: 35px;
      }
      p {
        width: 100px;
        font-size: 12px;
        color: #999;
        text-align: center;
      }

      i {
        position: absolute;
        background-position: -300px -350px;
        left: 163px;
        bottom: 35px;
      }
    }
    .btn-group {
      display: block;
      margin-top: 20px;
      margin-bottom: 15px;
      text-align: center;
      .btn {
        float:none;
        display: inline-block;
        width: 165px;
        margin:0 7px;
        background-color: #ee7474;
        font-size: 12px;
        line-height: 40px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .line10 {
      height: 10px;
      background-color: #f5f5f5;
    }
    .paypal {
      height: 120px;
      background-color: #fff;
      overflow: hidden;
      i {
        display: block;
        width: 74px;
        height: 19px;
        margin-top: 15px;
        margin-left: 15px;
        background-position: -100px -400px;
      }
      .email {
        font-size: 20px;
        line-height: 25px;
        margin-top: 15px;
        padding-left: 15px;
      }
      .name {
        margin-top: 15px;
        margin-left: 15px;
        font-size: 14px;
        color: #666;
        text-transform: capitalize;
      }
    }

    .ref-link {
      height: 198px;
      padding-left: 15px;
      padding-top: 15px;
      background-color: #fff;
      h4 {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }
      .link {
        margin-top: 15px;
      }
      input {
        width: 69%;
        height: 40px;
        padding:0 10px;
        background-color: #f0f0f0;
        border: 1px solid #7e7e7e;
        font-size: 12px;
        line-height: 40px;
        &:hover {
          cursor: text;
        }
      }
      .btn-copy {
        display: inline-block;
        width: 23%;
        background-color: #27ab86;
        font-size: 12px;
        line-height: 40px;
        color: #fff;
        text-align: center;
      }
      .share {
        margin-top: 25px;
        li {
          float: left;
          margin-right: 20px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        a {
          width: 50px;
          height: 50px;
          &.email {
            background-position: -300px -400px;
          }
          &.fb {
            background-position: -350px -550px;
          }
          &.tw {
            background-position: -350px -500px;
          }
          &.gp {
            background-position: -350px -450px;
          }
          &.pin {
            background-position: -350px -400px;
          }
        }
      }
    }
  }
  .credit-box {
    .val-box {
      position: relative;
      width: 345px;
      height: 145px;
      margin: 10px auto 30px;
      background: url(#{$img-path}global/img_points.png) no-repeat;
      background-size: contain;
      overflow: hidden;
      .points {
        margin-top: 30px;
        text-align: center;
        .count {
          font-size:24px;
          color: #111;
        }
      }
      .count {
        font-family:$font-sans-serif-base;
        font-weight: bold;
        line-height: 35px;
      }
      p {
        font-size: 12px;
        color: #999;
        text-align: center;
      }

      i {
        position: absolute;
        background-position: -350px -350px;
        left:165px;
        bottom:33px;
      }
    }

    .brief {
      padding: 0 15px;
      font-size: 14px;
      line-height: 24px;
      color: #111;

      p {
        line-height: 24px;
        margin: 10px auto;
      }
    }
  }
  .recode-list {
    padding: 15px;
    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      padding: 8px;
      border-bottom: 1px solid #eee;
      >div {
        flex:none;
        width: 50%;
        margin-bottom: 5px;
      }
      .date {
        color: #aaa;
      }
      .points,.balance {
        text-align: right;
      }

      &.used {
        .points {
          span {
            color: #fa303c;
          }
        }
      }
      &.got {
        .points {
          span {
            color: #299e42;
          }
        }
      }
    }
  }

  .redeem {
    form {
      margin: 30px 15px 0;
    }
    h3 {
      margin-top: 15px;
      font-size: 12px;
    }
    .val {
      display: inline-block;
      margin-left: 10px;
      font-size: 24px;
      color: #999;
      vertical-align: middle;
    }
    input {
      width: 100%;
      height:auto;
      margin-top: 5px;
      padding:0 20px;
      border:1px solid #ccc;
      font-size: 14px;
      line-height: 36px;
    }
    .btn-confirm {
      display: block;
      margin-top: 15px;
      background-color: #ee7474;
      font-size: 16px;
      line-height: 50px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
    }

    .info {
      padding: 18px 18px 0;
      ul {
        padding: 15px;
        background-color: #f1f1f1;
      }
      li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 24px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .dot {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        font-size: 18px;
        color: #666;
      }
    }
    .equal {
      margin-top: 16px;
      font-size: 12px;
      line-height: 25px;
      color: #999;
      span {
        display: inline-block;
        margin: 0 5px;
        font-size: 20px;
        color: #111;
        vertical-align: middle;
      }
    }
  }


  .sign-box {
    .form-box {
      margin-top: 10px;
      padding:0 15px;
      input[type="text"] {
        width: 100%;
        height: 50px;
        padding:0 17px;
        margin-top: 15px;
        font-size: 14px;
        line-height: 50px;
        border: 1px solid #ccc;
        box-shadow: none;
      }
      ::-webkit-input-placeholder {
        color: #999;
      }
      .btn-refer-sign {
        display: block;
        width: 100%;
        height: 50px;
        margin-top:30px;
        border:1px solid $color-main;
        background-color: $color-main;
        font-size: 16px;
        line-height:50px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
      }
      .no-login{
        font-size: 14px;
        font-weight: bold;
        margin-top: 50px;
        text-align: center;
      }
    }
    .info-box {
      margin-top: 25px;
      padding:0 15px;
      p {
        font-size: 12px;
        line-height: 24px;
        color: #999;
      }
      .tip-list {
        margin:20px 0;
        li {
          position: relative;
          padding-left: 20px;
          font-size: 12px;
          line-height: 35px;
          .dot {
            position: absolute;
            display: block;
            left: 3px;
            top: 0;
            font-size: 16px;
          }
          i {
            display: inline-block;
            width: 56px;
            height: 14px;
            margin-left: 5px;
            background-position: -200px -400px;
            vertical-align: middle;
          }
        }
      }
      .tips-last {
        margin-top: 20px;
        color: #111;
      }
    }
  }


}