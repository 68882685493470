.p-orderstatus {
  .order-status{
    padding:15px;

    h2 {
      margin-bottom: 20px;
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }

    form {
      width: 300px;
      margin: 30px auto;
    }

    label{
      margin-top: 10px;
      display: block;
      height:14px;
      line-height: 14px;
      padding-left: 0;
      font-family: $font-sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      color: $color-black;
      margin-bottom: 5px;
    }
    .ipt {
      width: 100%;
      height: 36px;
    }
    .ipt-order {
      margin-bottom: 15px;
    }
    .btn-view-order{
      display: block;
      width: 160px;
      height: 40px;
      margin: 0 auto;
      background-color: $color-main;
      font-size: 14px;
      color: #fff;
      line-height: 40px;
    }
  }



  .guide {
    margin-top: 30px;
    font-size: 12px;
    h4 {
      margin-top: 20px;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: bold;
    }
  }
  
  .bottom-tips {
    margin-top: 30px;
    font-size: 12px;
    color: #999;
    a {
      color: #0e5ad0;
    }
  }

}