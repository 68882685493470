//index css
.p-idx_old{
  //padding-top: $bodyPaddingTop;
  img {
    width:100%;
  }

  .index-slide {
    position: relative;
    width: 100%;
    margin: 0;
    border: none;
    box-shadow: none;
    aspect-ratio: 375/177;
    .wrap {
        display: block;
    }
    a {
      display: block;
    }
  }

  .category-a{
    a {
      display: block;
    }
    h2 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size:1.4rem;
      font-weight:bold;
      text-align: center;
    }

    .img-wrap{
      margin-bottom:15px;
      padding-left: 15px;
      padding-right:15px;
      img {
        aspect-ratio: 345/150;
      }
      &.full{
        padding-left: 0;
        padding-right: 0;
        img {
          aspect-ratio: 375/183;
        }
      }
    }
  }

  .category-b{
    a{
      display: block;
    }
    ul{
      padding:0 8px;
    }
    li{
      float: left;
      width: 50%;
      padding:0 8px;
      img {
        aspect-ratio: 164/246;
      }
    }
    h2{
      margin-top: 6px;
      margin-bottom: 15px;
      font-family:$font-serif;
      font-size: 1.6rem;
      text-align: center;
    }
  }

}
