.p-myfavorite {

  //padding-top: $bodyPaddingTop;
  .my-favorite {
    padding: 15px;

    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      margin-bottom: 15px;
      letter-spacing: 2px;
    }
  }

  .favorite-list {
    .prod-item {
      display: flex;
      padding: 2% 4%;
    }

    .img-wrap {
      width: 32%;
      margin-right: 4%;

      img {
        width: 100%;
      }
    }

    .info {
      width: 64%;
    }

    .prod-name {
      //width: 210px;
      max-height: 36px;
      overflow: hidden;
      margin-bottom: 10px;
      font-size: 14px;
      color: #111;
    }

    .prod-price {
      display: flex;
      align-items: flex-start;
      margin-top: 15px;
      font-size: 20px;
      line-height: 1;
      font-weight: bold;
      color:  $color-red2;
      text-transform: uppercase;
      .price-symbol {
        font-size: 12px;
        font-weight: normal;
        color: #666;
      }
    }

    .prod-price, .not-for-sale {
      font-size: 14px;
      font-weight: 600;
      color: #fa303c;
    }

    .prod-activity {
      display: inline-flex;
      justify-content: center;
      overflow: hidden;
      padding: 0 10px;
      //max-width: 181px;
      height: 26px;
      margin-top: 14px;
      border-radius: 13px;
      background-color: #ee7474;
      font-size: 14px;
      line-height: 26px;
      color: #fff;
    }

    .btn-remove {
      margin-top: 10px;
      font-size: 12px;
      color: #999999;
      text-decoration: underline;
    }

  }

  .empty-favorite {
    padding: 0 15px;
    text-align: center;

    .heart {
      width: 64px;
      height: 57px;
      margin: 72px auto 20px auto;

      img {
        width: 100%;
      }
    }

    p {
      font-size: 1.4rem;
      color: $color-gray2;
      margin-bottom: 175px;
    }

    .btn-continue {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      margin-top: 15px;
      background-color: #fff;
      color: $color-black;
      border: 1px solid $color-black;
    }
  }
}