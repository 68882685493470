//header

.p-prod header.fixedbar::before {
  width: 0;
  height: 0;
}

header{
  position: relative;
  width: 100%;
  padding:0 15px;
  background-color: #fff;
  //border-bottom:1px solid #f4f4f4;
  text-align: center;

  &.fixedbar {
    position: fixed;
    top: 0;
    left: 0;
    transform:translate(0,-100%);
    z-index: 3;
    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 38px;
    }
    &.in {
      transition: transform .3s;
      transform:translate(0,0);
    }
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
  }

  .logo {
    display: block;
    width: 135px;
    height: 24px;
    text-indent:-9999px;
    background: url('/build/sp_images/global/logo.png?0621') center no-repeat;
    background-size: contain
  }
  @media screen and (max-width: 350px) {
    .logo {
      width: 130px;
      height: 22px;
    }
  }

  .icon-s {
    //display: inline-block;
  }

  .left {
    flex: none;
    display: flex;
    align-items: center;
    width: 80px;
    .btn-nav {
      background-position: 0 0;
    }
    .btn-go-back {
      visibility: hidden;
      margin-right: 10px;
      background-position: -350px -300px;
    }
  }

  .right {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    a {
      display: block;
    }
    .btn-search {
      background-position: -100px 0;
    }
    .btn-favorite {
      background-position: -350px -100px;
    }
    .btn-cart {
      position: relative;
      background-position: -50px 0;
      .count {
        display: block;
        width: 16px;
        height: 16px;
        background-color: #ee7474;
        border-radius: 50%;
        position: absolute;
        bottom: 11px;
        right: 12px;
        font-size: 1.2rem;
        color: #fff;
        line-height: 16px;
        transform: translate(100%,0);
      }
    }
  }
  .search-box {
    position: relative;
    height: 54px;
    padding-top: 10px;
    border-bottom: 1px solid #111;
    .btn-search-submit {
      position: absolute;
      display: block;
      top: 15px;
      i {
        background-position: -100px 0;
      }
    }
    input {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 1.4rem;
      line-height: 2;
      border:none;
    }
    .btn-close {
      display: block;
      position: absolute;
      top: 20px;
      right: 0;
      i {
        background-position: -50px -350px;
      }
    }
  }

}

header .lower {
  height: 48px;
  font-size: 20px;
}

header .page-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 48px
}

.bottom-border {
  border-bottom: 1px solid #e9e9e9
}

.header-simple .left {
  width: 10%
}

.header-simple .right {
  width: 10%
}

.prev-page-btn {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.pl-box {
  display: none;
  width: 100%;
  height: 54px;
}
.textcomplete-dropdown{
  width: 90vw;
  overflow: scroll;
  left: 15px !important;
  top: 88px !important;
}
.textcomplete-dropdown>li>a {
  color: #999;
}
.textcomplete-dropdown>.active>a{
  color: #999;
  text-decoration: none;
  background-color: #fff;
  outline: 0;
}
.textcomplete-dropdown>.active>a:active{
  color: #fff;
  background-color: #ee7474;
}
/*.top-countdown {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  background-color: #000;
  font-size:1.2rem;
  line-height:20px;
  color: #fff;
  text-align: center;
  z-index:$z10;
}

.top-countdown{
  &+header{
    margin-top: 20px;
    &.fixedbar {
      margin-top: 0;
      top: 20px;
      &+.pl-box {
        margin-top: 20px;
      }
    }
  }
  &.hide+header{
    margin-top: 0;
  }
} */




#nav-modal {
  .modal-dialog {
    position: absolute;
    height:100%;
    width: 77%;
    overflow: auto;
    margin: 0;
    bottom: 0;
  }
  .modal-content {
    border-radius:0;
    border:none;
    padding:0 15px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &.fade {
    .modal-dialog {
      transform:translate(-100%,0);
    }
  }
  &.in {
    .modal-dialog {
      transform: translate(0,0);
    }
  }

  .global-message {
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
  }

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.4rem;

    .font-main {
      width: 75vw;
      font-size:1.4rem;
      font-weight:600;
    }
    ul{
      position: relative;
    }
    .line {
      width: 76.94vw;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #f5f5f5;
    }
    li {
      margin: 25px 0;
    }
    .line-li {
      margin: 0;
    }
    .hello {
      margin-top: 0;
      margin-bottom: 20px;
      .icon-user {
        display: inline-block;
        width: 18px;
        height: 19px;
        background: url("#{$img-path}global/icon_user.png");
        background-size:18px 19px;
        margin-left: 5px;
        vertical-align: baseline;
      }
      p{
        display: inline-block;
        margin-left: 15px;
        white-space: nowrap;
        width: 46vw;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .btn{
        display: block;
        margin-right: 0;
        margin-top: 10px;
        height: 30px;
        width: 69vw;
        line-height: 30px;
        background-color: #f2f2f2;
        border-radius: 100px;
        font-size:1.2rem;
        text-align: center;
        font-weight: bold;
      }

      .no-sign {
        color: #4395ff;
        position: absolute;
        right: 0;
        top: 0;
        p {
          display: inline;
          margin-left: 15px;
        }
        .or {
          margin: 0;
          color: $color-black;
        }
      }

      .new-user-tips2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 76.94vw;
        height: 28px;
        background-color: #faf0d5;
        margin: 20px -15px;
        font-size: 12px;
        color: #f39800;
        .em {
          display: inline-block;
          margin: 0 3px;
          font-weight: bold;
        }
      }

      .btn-group {
        display: flex;
        .btn{
          &:first-child{
            margin-right: 11px;
          }
        }
      }
    }
    .home {
      margin: 15px 0;
    }
    .leve1 {
      position: relative;
      i{
        position: absolute;
        background-position: 0 -300px;
        right: 0;
        top: 6px;
        width: 12px;
        height: 7px;
      }
    }
    .leve2 {
      margin-left: 20px;
      .nav-child {
        .back-prev{
          font-weight: 600;
          margin-left: -20px;
          i{
            background-position: -250px -300px;
            margin: 0 12px 0 0;
          }
        }
        i{
          display: inline-block;
          margin-left: 15px;
          background-position: -200px -350px;
          width: 7px;
          height: 13px;
          vertical-align: middle;
          margin-top: 2px;
        }
      }
    }
    .icon-rotate {
      margin-right: 25px;
      transform: rotate(0deg);
      transition: all 0.5s;
    }
    .lang{
      margin-top: 10px;
      margin-bottom: 10px;
      position: relative;
    }
    .pay{
      @extend .lang;
    }
    .slc{
      width: 75vw;
      line-height: 30px;
      background: url("#{$img-path}global/icon_arrow-bottom4.png") no-repeat right;
      background-size: 12px 7px;
      background-origin: content-box;
      padding-right: 15px;
      margin-left: -10px;
      border: none;
      appearance:none;
      box-shadow: none;
    }
    .service-btn,
    .out-btn {
      display: block;
      margin-right: 0;
      margin-top: 15px;
      height: 30px;
      width: 69vw;
      line-height: 30px;
      background-color: #f2f2f2;
      border-radius: 100px;
      font-size:1.2rem;
      text-align: center;
      .icon-service {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        background-position: -250px -350px;
      }
    }
    .out-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 18px;
      i {
        width: 16px;
        height: 16px;
        margin-right: 18px;
        background-position: -100px -650px;
      }
    }
  }
}

/* top banner*/
.top-banner-wrap {
  position: relative;
  width: 100%;
  height: 38px;
  //aspect-ratio: 390/38;
}
.p-prod {
  .top-multi-banner {
    position: relative;
  }
}
.top-multi-banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 38px;
  //aspect-ratio: 390/38;
  background-color: #000;
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
  }
  img {
    display: block;
    width: 100%;
  }
}