.p-vip {

  .banner {
    margin-top: 15px;
    margin-bottom: 30px;
    a {
      display: block;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .s-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .vip-slide {
      position: relative;
      width: 100%;
      margin: 0;
      border: none;
      box-shadow: none;
      aspect-ratio: 375/177;
      .wrap {
        display: block;
      }
      a {
        display: block;
      }
  }

  .vip-swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    .swiper-pagination-bullet {
      margin-left: 10px;
    }
  }

  .vip-nav {
    position: sticky;
    top: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    height: 34px;
    margin: 30px -15px;
    padding: 0 20px;
    background-color: #fbf9f9;
    z-index: 1;
    .item {
      flex: none;
      font-size: 12px;
      color: #86868a;
      margin-left: 20px;
      &.cur {
        color: #ee7474;
      }
    }
  }

  .prod-list {
    margin-left: -7px;
    margin-right: -7px;
    padding: 0;
    .prod-item {
      padding: 0 7px;
      .mark-icon-box {
        padding: 0 7px;
      }
    }

    &.square {
      .prod-item {
        .prod-img-wrap {
          aspect-ratio: 1/1;
        }
        .prod-img {
          aspect-ratio: 1/1;
        }
      }
    }
  }

  .link-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    .item {
      flex:none;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 47.8%;
      height: 40px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #111111;
      text-transform: uppercase;
      &.empty {
        height: 0;
        margin-bottom: 0;
        background-color: transparent !important;
      }
      &:nth-of-type(3n+1) {
        background-color: #fde3e2;
      }
      &:nth-of-type(3n+2) {
        background-color: #ffeae3;
      }
      &:nth-of-type(3n+3) {
        background-color: #fef4e8;
      }
    }
  }

  .btn-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin: 20px auto 50px;
    padding: 0 20px;
    border: 1px solid #111;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

}
