//checkout
//--------------------

.p-chk {
  //padding-top: $bodyPaddingTop;
  background: #e9e9e9;

  .container {
    padding: 20px 15px;
    margin-bottom: 10px;
    background: #fff;
  }

  .fixed-form-spacer {
    height: 130px;
  }

  .auth-tips {
    font-size: 14px;
    color: #999;
    line-height: 26px;
  }

  .process-tips {
    table {
      width: 100%;
      margin-top: 12px;
      font-size: 1.3rem;
      font-weight: bold;
      color: #999;

      td.active {
        color: $color-red1;
      }
    }
  }

  .container {
    input,
    select {
      height: 36px;
    }

    h3.title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 10px;
      color: #111;
      text-transform: capitalize;

      a {
        font-size: 12px;
        line-height: 1.5em;
        color: #666;
        text-transform: capitalize;

        & + a {
          margin-right: 15px;
        }
      }
    }

    h4.title-2 {
      margin-top: -8px;
      font-size: 12px;
      line-height: 2.6em;
      color: #666;
      text-transform: uppercase;
    }

    &.on {
      .title {
        color: #111;
      }
    }

    .btn-continue,
    .btn-try-again,
    .btn-add {
      display: block;
      margin: 12px 0;
      font-size: 1.6rem;
      line-height: 50px;
      text-align: center;
      background-color: $color-main;
      color: #fff;

      &.disabled {
        background-color: #eee;
        color: #111;
      }
    }
  }
  .js-address-selected {
    .address-show {
      border-bottom: none;
      margin:0;
      padding-bottom:0;
    }
  }

  .step-shipping {
    h4 {
      margin-bottom: 10px;
      font-size: 1.2rem;
      color: #666;
    }

    .methods {

      .selected-shipping,
      li {
        margin-top: 12px;
        padding: 4px 14px;
        border: 1px solid #ccc;

        &.on {
          border: 1px solid #666666;

          .info {
            display: block;
          }
        }

        .info {
          display: none;

          .i-item {
            display: flex;
            padding: 10px 20px;
            margin-bottom: 10px;
            background-color: #f7f3ef;
            font-size: 12px;
            color: #59636a;
            align-items: center;

            i {
              margin-right: 14px;
            }

            &.proc {
              i {
                background-position: 0 -650px;
              }
            }

            &.ship {
              i {
                background-position: -50px -650px;
              }
            }
          }
        }

        &.disabled {
          color: #7d7d7d;
        }
      }

      table {
        width: 100%;
        font-size: 12px;
        line-height: 1.8em;
        text-transform: capitalize;

        .radio-btn {
          width: 22px;
        }

        .s_name {
          font-weight: bold;
        }

        .stress {
          color: $color-main;
        }

        .js-shipping-fee {
          del {
            color: #333;
          }
        }
      }

      .selected-shipping {
        border: 1px solid $color-red1;
      }

      .protection {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 18px;

        label {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: normal;
        }

        input {
          height: auto;
          margin: 0 8px 0 0;
        }

        .btn_question {
          margin-left: 10px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

    }

    .arrival {

      .arrival-list,
      .selected-arrival {
        li {
          overflow: hidden;
          margin-bottom: 12px;
          padding: 0 15px;
          border: 1px solid #ccc;
          font-size: 14px;
          line-height: 34px;

          &.on {
            border: 1px solid #666;
          }

          .a_info {
            display: flex;
            width: 100%;
          }

          span {
            display: block;
          }

          .a_icon {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }

          .a_name {
            width: 38%;
            font-weight: bold;
            text-transform: capitalize;
          }

          .a_date {
            width: 33%;
            font-size: 12px;
            color: $color-red1;
          }

          .a_fee {
            width: 31%;
            text-align: right;
          }

        }

        .disabled {
          color: #7d7d7d;

          .disabled-tip, .tax-disabled-tip {
            display: block;
            margin-top: -6px;
            padding-bottom: 12px;
            font-size: 12px;
            color: #fa303c;
            line-height: 1;
          }

          .tax-disabled-tip {
            color: #999999;
          }
        }

        table {
          width: 100%;
          font-size: 12px;
          line-height: 1.8em;
          text-transform: capitalize;

          .radio-btn {
            width: 22px;
          }

          .title {
            font-size: 14px;
          }

          .stress {
            color: $color-main;
          }
        }
      }

      .selected-arrival {
        li {
          overflow: hidden;
          border: 1px solid $color-main;
        }
      }

      .rush_icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }

  .fixed-submit {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    padding: 12px 20px 15px;
    margin: 0 auto;
    border-top: 1px solid #e9e9e9;

    .grand-total-info {
      font-size: 14px;
      line-height: 16px;
      padding: 2px 0;

      .grand-total-line {
        .grand-total-amount {
          font-weight: 700;
          color: #eb3223;
        }
      }

    }

    .grand-total-saving-line {
      font-size: 12px;
      line-height: 14px;
      color: #eb3223;
      margin: 8px auto;
    }

    .btn-place-order {
      margin: 10px auto;
    }
  }

  .step-payment-methods {
    img {
      max-width: 100%;
    }

    input {
      height: 20px;

      &:focus {
        outline: none;
      }
    }

    td {
      padding: 0;

      &:nth-of-type(1) {
        padding-right: 10px;
      }
    }

    label {
      margin-bottom: 0;
    }
  }

  .cart-list {

  }

  .service-description {
    padding: 0 10px;
    font-size: 1.4rem;
  }

  .step-redeem {
    margin-top: 5px;

    .use-redeem {

      input {
        height: 28px;
        margin: 0 10px;
        vertical-align: middle;
      }

      label {
        display: block;
        margin-bottom: 0;
        //background-color: #f8f8f8;
        font-size: 12px;
        font-weight: normal;
        color: #999;
        line-height: 28px;

        span {
          font-size: 14px;
          vertical-align: middle;
        }

        .qty {
          color: #111;
        }

        .val {
          color: #fa303c;
        }

      }
    }
  }


  .summary {
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;

    .total-list {
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
        padding: 3px 0;
        font-size: 14px;

        &.total-price {
          span {
            font-weight: bold;
            font-size: 1.8rem;

            &:last-child {
              color: #cc0000;
            }
          }
        }

        .summary-shipping-fee {
          display: flex;
        }

        &.has-tips {
          .btn-tips {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin-left: 3px;
            border: 1px solid #cbcbcb;
            background-color: #eeeeee;
            border-radius: 50%;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      span {
        display: block;
        // padding:3px 0;
        text-align: right;
      }

      del {
        display: block;
        margin-right: 5px;
        color: #999;
      }

    }

  }

  .order-total {
    h3 {
      text-transform: capitalize;

      span {
        font-size: 16px;
        font-weight: bold;

        &:last-child {
          color: $color-red2;
        }
      }
    }

    .js-ab-payment {
      width: 100%;

      dl {
        background: #fff;

        dt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 12px;
          min-height: 55px;
          position: relative;

          label {
            display: flex;
            vertical-align: middle;
          }

          span.text {
            margin-left: 8px;
            font-weight: bold;
            vertical-align: middle;
            line-height: 45px;
            font-size: 12px;
            font-family: sans-serif;
          }

          img {
            width: 70px;
            vertical-align: middle;
          }
        }
      }

      .paymet-item-title {
        display: flex;
        align-items: center;

        input {
          margin: 0;
        }

        .text {
          height: 30px;
          line-height: 30px;
        }
      }

      .card-list {
        overflow: hidden;

        li {
          margin-bottom: 10px;
          overflow: hidden;
          padding: 8px 0;
          border: 1px solid transparent;
          text-align: center;

          img {
            height: 36px;
          }
        }
      }

      input {
        height: 36px;
      }

      @at-root #payment-card {
        vertical-align: middle;
      }
    }

    .payment-input {
      width: 100%;
      position: relative;
      margin-top: 15px;

      iframe {
        width: 100%;
        height: 320px;
      }


      .payment-notice {
        padding-top: 15px;
        font-size: 14px;
        line-height: 19px;
        color: #999999;
      }
    }
  }

  .payment-input {
    font-size: 1.4em;

    .label {
      display: block;
      margin-top: 8px;
      margin-bottom: 1px;
      padding-left: 0;
      text-align: left;
      font-weight: normal;
      color: #111;

      .require {
        display: inline-block;
        margin-left: 10px;
        font-size: 1.8rem;
        line-height: 4px;
        vertical-align: bottom;
      }
    }

    .input-zoom {
      position: absolute;
      top: -25px;
    }

    input,
    select {
      font-size: 1.4rem;
      line-height: 36px;
    }

    .date-box {
      select {
        width: 46%;
        color: #999;

        &.selected {
          color: #111;
        }
      }

      span {
        width: 46%;
      }

    }

    .placeholder {
      color: #999;
    }

  }

  .btn-place-order,
  .btn-payment {
    display: block;
    margin: 30px 0;
    background-color: $color-main;
    font-size: 16px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    &.disabled {
      font-weight: bold;
      background-color: #eee;
      color: #111;
    }
  }

  .no-dollar-tips {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 1.67;
    color: #999999;
  }

  .safety-box {
    margin-top: 15px;
    padding-bottom: 50px;

    .lock {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #00b15d;

      img {
        margin-right: 10px;
        width: 31px;
        height: 31px;
      }
    }

    .safe_img {
      margin-top: 15px;
    }
  }

  .icon-top {
    display: none;
  }
}

.step-coupon {
  #coupon-code-input-wrap {
    padding-top: 10px;
    border-top: 1px solid #f5f5f7;
    padding-bottom: 10px;
  }
}

#coupon-code-input-wrap {
  .coupon-header {
    display: flex;
    height: 24px;
    justify-content: space-between;
    align-items: center;

    .coupon-title {
      font-size: 14px;
      font-weight: bold;
      color: #111;
    }

    .more-coupons {
      color: #666;
      font-size: 1.1rem;
    }
  }

  .code-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;

    .ipt-coupon {
    }

    input.readonly {
      background: #f9f9f9;
    }

    .btn {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 34px;
      background-color: #fff;
      margin-left: 10px;
      padding: 8px 22px 7px;
      border: solid 1px #444;
      font-size: 1.3rem;
      color: #444;
      text-transform: uppercase;
    }
  }

  .error {
    color: red;
  }

  .success {
    color: #63b40c;
  }

  .coupon-tips {
    margin-top: 6px;
    font-size: 12px;
  }
}

#checkout-coupons-modal {
  h4 {
    font-size: 1.4rem;
    font-weight: bold;
    font-family: sans-serif;
    height: 26px;
    line-height: 26px;
    margin-bottom: 6px;
  }

  .couponRecommend {

  }

  ul.coupon-list {
    li {
      width: 100%;
      margin: 0 auto 10px;
      border: solid 1px #f0f0f0;
      background-color: #f9f9f9;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .couponCodeBox {
        width: 28%;
        border-right: 1px dashed #ccc;
        display: flex;
        flex-flow: column;
        align-items: center;

        .coupon-code {
          font-size: 1.9rem;
          color: #ec5555;
          font-weight: bold;
          font-family: sans-serif;
          text-align: center;
        }

        .coupon-valid-info {
          text-align: center;
          margin: 10px auto;
          color: #555;
        }

        .btn-copy {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12vw;
          height: 6vw;
          border: solid 1px #999;
          font-size: 3vw;
          color: #555;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .couponDescription {
        width: 63%;

        .coupon-title {
          text-align: center;
          font-weight: bold;
          font-family: sans-serif;
          color: #555;
          margin: 4px auto 10px;
          font-size: 1.4rem;
        }

        .coupon-description {
          margin: 10px 0;
          text-align: center;
          font-size: 1.1rem;

          p {
            line-height: 20px;
            color: #555;
          }
        }
      }
    }
  }
}

#paypal-checkout-button-container {
  .error-box {
    display: none;
    font-size: 14px;
    color: red;
  }
}

.js-address-list {
  i {
    font-size: 20px;
    color: #333;
  }

  .js-address-choose {

  }
}