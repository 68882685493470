.p-info{
  .checkbox {
    margin: 0;
    padding: 15px;
    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      margin-bottom: 20px;
      letter-spacing: 2px;
    }
    label{
      display: block;
      height:14px;
      padding-left: 0;
      font-family: $font-sans-serif;
      font-size: 1.2rem;
      color: $color-black;
    }
    .warn-w {
      display: none;
      width: 100%;
      padding-top: 5px;
      margin-bottom: 0;
      font-size: 1.2rem;
      color: #f64e42;
    }
    .email {
      margin-bottom: 8px;
      .email-ipt {
        width: 68%;
        height: 36px;
      }
      .email-ipt[disabled] {
        background-color: #fff;
        opacity: 1;
      }
      .email-btn {
        width: 28%;
        height: 36px;
        display: block;
        color: #fff;
        font-size: 1.6rem;
        line-height:36px;
        background-color: $color-main;
      }
    }
    .ipt {
      width: 100%;
      height: 36px;
    }
    .slc{
      width: 100%;
      height: 36px;
      background: url("#{$img-path}global/icon_arrow-bottom.png") no-repeat 97% center;
      background-size: 14px 9px;
    }
    .nickname{
      margin-bottom: 8px;
    }
    .birthday {
      @extend .nickname;
      select {
        width: 31%;
        height: 36px;
        line-height: 36px;
        appearance:none;
        color: $color-black;
        font-size: 1.2rem;
        font-family: $font-sans-serif;
        background: url("#{$img-path}global/icon_arrow-bottom.png") no-repeat 90% center;
        padding-right: 7%;
        background-size: 14px 9px;
        option {
          color: $color-black;
          font-size: 1.2rem;
          font-family: $font-sans-serif;
        }
      }
      .day {
        margin-right: 3.5%;
      }
    }

    .country {
      @extend .nickname;
    }

    .gender {
      @extend .nickname;
    }

    .phone {
      @extend .nickname;
    }

    .postcode {
      margin-bottom:15px;
    }

    .update-btn {
      width: 100%;
      height: 50px;
      display: block;
      color: #fff;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
    }
  }
  #verify-ok{
    .modal-content {
      width: 85.3vw;
      height: 100%;
      box-shadow: none;
      margin: 0 auto;
      text-align: center;
      margin: 36vh auto;
      h4{
        margin-top:47px;
        margin-bottom: 44px;
        font-size: 1.4rem;
      }
      .modal-footer {
        border-top: solid 1px #b9b9b9;
        font-size:1.6rem;
        padding: 0;
        line-height: 58px;
        a{
          display: block;
          width: 100%;
          text-align: center;
          color: #3599fc;
        }

      }
    }
  }

}