.p-order {
  .order-body {
    padding: 15px;
    font-family: $font-sans-serif;
    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }
    .list-header {
      height: 38px;
      background-color: #f2f2f2;
      padding: 12px 10px;
      line-height: 38px;
      margin-top: 15px;
      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 14px;

      }
      p {
        line-height: 14px;
      }
      .no-paid {
        color:  $color-red2;
      }
    }
    .item-list {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 10px;
      user-select: none;
      ul {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: visible;
        -webkit-overflow-scrolling: touch;
        margin-top: 15px;
      }
      li {
        display: inline-block;
        max-width:100%;
        width: 30%;
        .fabric-img {
          max-height: 100%;
        }
        a{
          display: block;
        }
      }
    }
    .item-num {
      font-size:1.2rem;
      color: $color-gray2;
    }
    p{
      line-height: 15px;
      margin-bottom: 15px;
    }
    .total-money {
      font-size:1.4rem;
      color:$color-black ;
      span{
        color:  $color-red2;
      }
    }
    .button .btn {
      width: 48%;
      height: 40px;
      background-color: #fff;
      border:1px solid $color-black;
      color: $color-black;
      vertical-align: middle;
      padding: 10px 0;
      font-size: 1.4rem;
      &.checkout-btn {
        background-color: $color-main;
        color: #fff;
        border-color: $color-main;
      }
    }
    .line {
      margin-bottom: 15px;
    }
    .wide-line {
      margin-top:15px;
      width: 100vw;
      height: 10px;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #f5f5f5;
    }
  }
}
