.p-fail {
  //padding-top: $bodyPaddingTop;
  .fail-a {
    width: 100%;
    text-align: center;
    padding: 15px 15px 0 15px;
    font-family: $font-sans-serif;
    .icon {
      height: 86px;
      width: 86px;
      background-color: #f5685e;
      margin:0 auto;
      border-radius: 50%;
      padding: 24px;
      i {
        margin:auto;
        width: 40px;
        height:40px;
        vertical-align:middle;
        background-position: -102px -250px;
      }
    }
    h2 {
      font-size: 1.8rem;
      margin-top: 10px;
      font-family: $font-serif;
      letter-spacing: 2px;
    }

    h2:nth-of-type(2) {
      margin: 25px 0 21px 0;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.71;
      letter-spacing: 0;
      font-family: $font-sans-serif;
    }
    .article-box {
      line-height: 1.71;
      text-align: left;
      color: $color-gray2;
      font-size: 1.4rem;
    }
    .btn {
      width: 100%;
      color: #fff;
      line-height: 50px;
      font-size: 1.6rem;
      font-weight: 600;
      background-color: $color-main;
      margin-top: 15px;
    }
    //.paypal-btn {
    //  background-color: #fff;
    //  color: $color-black;
    //  border:solid 1px $color-gray1;
    //}
  }
}