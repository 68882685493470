
.tulleBlushing_Pink {
  background-color: #fbe5e7;
}

.tulleCandy_Pink {
  background-color: #e67aa2;
}

.tullePearl_Pink {
  background-color: #F0AFAB;
}

.tulleWatermelon {
  background-color: #E2535E;
}

.tulleRuby {
  background-color: #ce2424;
}

.tulleBurgundy {
  background-color: #521921;
}

.tulleOrange {
  background-color: #ED714C;
}

.tulleDaffodil {
  background-color: #FFFAC0;
}

.tulleWhite {
  background-color: #FFFFFF;
}

.tulleIvory {
  background-color: #FFFDF7;
}

.tulleChampagne {
  background-color: #fcf4e2;
}

.tulleGold {
  background-color: #e2c26c;
}

.tulleBrown {
  background-color: #624638;
}

.tulleChocolate {
  background-color: #41322B;
}

.tulleBlack {
  background-color: #171717;
}

.tulleSilver {
  background-color: #BFC1C5;
}

.tulleDark_Green {
  background-color: #024E40;
}

.tulleJade {
  background-color: #589EA0;
}

.tulleClover {
  background-color: #87934C;
}

.tulleLime_Green {
  background-color: #85cf4e;
}

.tulleSage {
  background-color: #C3D7BE;
}

.tulleSky_Blue {
  background-color: #94ABCF;
}

.tullePool {
  background-color: #5294d2;
}

.tulleOcean_Blue {
  background-color: #375C9E;
}

.tulleRoyal_Blue {
  background-color: #2C4593;
}

.tulleInk_Blue {
  background-color: #0C4868;
}

.tulleDark_Navy {
  background-color: #2F355E;
}

.tulleRegency {
  background-color: #371B79;
}

.tulleGrape {
  background-color: #5A2364;
}

.tulleFuchsia {
  background-color: #bc3977;
}

.tulleLilac {
  background-color: #B173C6;
}

.tulleLavender {
  background-color: #8D7AC8;
}

.tulleOff_White {
  background-color: #EAE4E8;
}

.tulleCream {
  background-color: #EAE9CB;
}

.tulleAubergine {
  background-color: #793444;
}

.tulleWarm_Mocha {
  background-color: #674e34;
}

.tulleEmerald {
  background-color: #004721;
}

.tulleDusty_Blue {
  background-color: #BFD4D6;
}

.tulleMagenta {
  background-color: #691950;
}

.tulleVanilla {
  background-color: #E7B689;
}
