
/*default as chiffon*/
.atAubergine  {
  background-color: #98385A;
}

.atBlack  {
  background-color: #2D3129;
}

.atBlush  {
  background-color: #F9C6B7;
}

.atBlushing_Pink  {
  background-color: #FFE4EC;
}

.atRose_Pink  {
  background-color: #FED4E7;
}

.atCashmere  {
  background-color: #D6CBC6;
}

.atChampagne  {
  background-color: #E3D0A7;
}

.atSmoke  {
  background-color: #707579;
}

.atWarm_Mocha  {
  background-color: #513226;
}

.atClover  {
  background-color: #ADBA58;
}

.atDaffodil  {
  background-color: #FFFED8;
}

.atDark_Navy  {
  background-color: #212A67;
}

.atEggshell  {
  background-color: #F6F1E7;
}

.atEmerald  {
  background-color: #047C40;
}

.atCockscomb  {
  background-color: #DC39A1;
}

.atGrape  {
  background-color: #571F57;
}

.atHot_Raspberry  {
  background-color: #FD73B0;
}

.atIce_Mint  {
  background-color: #D5FDE8;
}

.atIce_Yellow  {
  background-color: #FFFA84;
}

.atInk_Blue  {
  background-color: #194C7D;
}

.atIvory  {
  background-color: #FFFDF7;
}

.atJamaican_Mist  {
  background-color: #8FF6D9;
}

.atLavender  {
  background-color: #A084B7;
}

.atDusty_Blue  {
  background-color: #D5E6F4;
}

.atLilac  {
  background-color: #E7CCF9;
}

.atLime_Green  {
  background-color: #DBF549;
}

.atMagenta  {
  background-color: #971D78;
}

.atMallard  {
  background-color: #055C69;
}

.atRoyal_Blue  {
  background-color: #232DB1;
}

.atLight_Sky_Blue  {
  background-color: #DFEEFA;
}

.atRuby  {
  background-color: #C63132;
}

.atSage  {
  background-color: #E2FABC;
}

.atTurquoise  {
  background-color: #0FABB1;
}

.atVanilla  {
  background-color: #FFE0CA;
}

.atWatermelon  {
  background-color: #FE787C;
}

.atWhite  {
  background-color: #FFFFFF;
}

.atBrown  {
  background-color: #503429;
}

.atBurgundy  {
  background-color: #5B242E;
}

.atCandy_Pink  {
  background-color: #F8A2BF;
}

.atChocolate  {
  background-color: #271C19;
}

.atDark_Green  {
  background-color: #2B412D;
}

.atFuchsia  {
  background-color: #C83980;
}

.atGold  {
  background-color: #DBC086;
}

.atJade  {
  background-color: #119797;
}

.atOcean_Blue  {
  background-color: #2047A8;
}

.atOrange  {
  background-color: #E54F29;
}

.atPearl_Pink  {
  background-color: #F5E0D3;
}

.atPool  {
  background-color: #1281C8;
}

.atRegency  {
  background-color: #1F153A;
}

.atSilver  {
  background-color: #B8B9B2;
}

.atSky_Blue  {
  background-color: #82A2C6;
}

.atCream  {
  background-color: #EAE9CB;
}

.atMulti  {
  background: linear-gradient(to right, #aa3534 33.33%, #fef984 33.33%, #fef984 66.66%, #212eb1 66.66%, #212eb1 99.99%);
}

.atOmbre  {
  background: linear-gradient(360deg, #212eb1, #90f5da);
}

.atTwo_Tone  {
  background: linear-gradient(180deg, #fff0f0 50%, #f9c4b6 50%);
}


[class*='White'],
[class*='Cream'],
[class*='Ivory'],
[class*='Ice_'],
[class*='Daffodil'] {
  box-shadow: 0 0 2px #c7c7c7 inset
}
