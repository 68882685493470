.p-editadd {
  .add-box {
    padding:15px;
    h2 {
      margin-bottom: 20px;
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
    }
    h3{
      height:14px;
      font-family: $font-sans-serif;
      font-size: 1.2rem;
      color: $color-black;
      min-height: 20px;
      line-height: 15px;
      span {
        font-size: 1.8rem;
        position:relative;
        top: 6px;
      }
    }
    div {
      margin-bottom: 8px;
      .is-error {
        display: block;
        padding-top: 5px;
      }
    }
    .ipt {
      width: 100%;
      height: 36px;
      font-size: 1.4rem;
      font-family: $font-sans-serif;
    }

    .slc{
      width: 100%;
      height: 36px;
      background: url("#{$img-path}global/icon_arrow-bottom.png") no-repeat 97% center;
      background-size: 14px 9px;
    }
    .submit-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
      margin-top: 5px;
      margin-bottom: 10px;
      color: #fff;
    }
  }
}

.input-address {
  label {
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: normal;
    text-transform: capitalize;
  }

  .form-group {
    margin-bottom: 10px;

    &.item-email {
      .tips-inline {
        color: #EB5555;
      }
    }
  }

  .form-control {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    font-size: 1.4rem;
    line-height: 36px;
  }

  .check {
    height: 20px;
    margin-top: 15px;
    margin-bottom: 5px;

    .checkbox {
      display: inline;
      margin-top: 0;
      height: 24px;
      margin-right: 6px;
      width: auto;
      box-shadow: none;
    }

    span {
      line-height: 24px;
    }
  }

  #address {
    padding-left: 30px;
    background-image: url('../images/global/icon_address.png');
    background-position: 5px center;
    background-size: 20px 20px;
    background-repeat: no-repeat;

    &.isSearch {
      background-image: url('../images/global/icon_search_1.png');
    }
  }

  .save-address {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    .address-message {
      margin: 10px auto;
    }

    .btn-continue {
      margin: 0;
    }
  }
}

.selected-address {
  .address-show {
    .address-choose {
      width: 30px;
      transform: rotate(270deg);

      i {
        font-size: 20px;
      }
    }
  }

  .cur {
    //border: 1px solid #ccc;
  }
}

.btn-save,
.btn-add {
  display: block;
  margin: 12px 0;
  font-size: 1.6rem;
  line-height: 50px;
  text-align: center;
  background-color: $color-main;
  color: #fff;

  &.disabled {
    background-color: #eee;
    color: #111;
  }
}

.address-show {
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid #ccc 1px;

  .address-info {
    display: flex;
    flex-direction: column;
    width: 86%;

    i {
      font-size: 18px;
    }

    ul {
      li {
        font-size: 1.4rem;
        line-height: 26px;
        overflow: hidden;
        word-wrap: break-word;
      }
    }

    .address-edit {
      display: flex;
      align-items: center;
      margin-top: 6px;

      span {
        font-size: 13px;
      }
    }
  }

  .address-choose {
    width: 30px;
    transform: rotate(270deg);
  }
}

#addressListModal {
  .modal-content {

    .btn-add {
      background-color: #fff;
      display: block;
      font-size: 16px;
      line-height: 50px;
      color: #333;
      text-align: center;
      text-transform: uppercase;
      border: 1px solid #999;
      margin-top: 20px;
    }

    .modal-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 15px;
      background-color: #fff;
      border-top: 1px solid #e9e9e9;

      .btn-confirm {
        background-color: #ee7474;
        display: block;
        font-size: 16px;
        line-height: 50px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        margin:5px auto;
      }
    }
  }
  .fixed-form-spacer {
    height: 60px;
  }
}

.fixed-form-spacer {
  height: 130px;
}

.pac-container {
  background-color: #FFF;
  z-index: 20;
  position: fixed;
  display: inline-block;
  float: left;
}
.modal{
  z-index: 20;
}
.modal-backdrop{
  z-index: 10;
}

