.p-user {
  //padding-top: $bodyPaddingTop;
  .item-list{
    margin-bottom: 40px;
    h1 {
      margin-top: 15px;
      margin-bottom: 40px;
      font-size: 1.8rem;
      text-align: center;
      font-family: $font-serif;
      letter-spacing: 2px;
    }
    ul{
      padding: 0 8px;
      margin: 0 auto;
      overflow:hidden;
    }
    li{
      width: 49%;
      display: inline-block;
      padding: 0 8px;
      font-size:1.4rem;
      color: $color-black;
      text-align: center;
      vertical-align: text-top;
      i {
        width: 42px;
        height: 42px;
        margin: 30px auto 10px auto;
      }
      .icon-info {
        margin-top: 0;
        background-position: -50px -100px;
      }
      .icon-order {
        margin-top: 0;
        background-position: -150px -100px;
      }
      .icon-favorite {
        background-position: -200px -100px;
      }
      .icon-history {
        background-position: -200px -650px;
      }
      .icon-address {
        background-position: -250px -100px;
      }
      .icon-change {
        background-position: -100px -100px;
      }
      .icon-refer {
        background-position: 0 -400px;
      }
      .icon-points {
        background-position: -50px -400px;
      }
    }
    a{
      display: block;
      height: 82px;
    }
    h2 {
      font-size: 1.4rem;
      font-family: $font-sans-serif;
    }
  }
}

.p-reg-welcome {
  .content-body {
    padding: 0 15px;
    .success {
      height: 86px;
      width: 86px;
      background-color: #46ae65;
      margin:25px auto;
      border-radius: 50%;
      padding: 24px;
      i {
        margin:auto;
        width: 40px;
        height:40px;
        vertical-align:middle;
        background-position: -150px -250px;
      }
    }

    .title {
      font-family: $font-serif;
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      text-align: center;
      .em {
        display: inline-block;
        margin: 0 3px;
        font-weight: bold;
        color: #fa303c;
      }
      a {
        color: #0068d9;
      }
    }

    .text-tips {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      color: #86868a;
    }

    .btn {
      display: block;
      margin-top: 20px;
      background-color: #ee7474;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}

#point-brief {

  .modal-dialog {
    position: absolute;
    width:100%;
    overflow: auto;
    margin: 0;
    top:0;
    bottom: 0;
  }
  .modal-content {
    border-radius:0;
    border:none;
    height:100vh;
    padding: 0 15px;
    background-color: #fff;
    overflow: auto;
  }
  .modal-header {
    margin:0 -15px;
    padding: 0;
    height: 54px;
    border-bottom: 1px solid #e5e5e5;
    .back {
      display: block;
      position: relative;
      width:60px;
      height:54px;
      i {
        display: block;
        position: absolute;
        top: 17px;
        left: 20px;
        background-position: -350px -300px;

      }
    }
  }
  &.fade {
    .modal-dialog {
      transform:translate(0,0);
    }
  }
  &.in {
    .modal-dialog {
      transform: translate(0,0);
    }
  }

  .title {
    margin-top: 15px;
    font-family: $font-serif;
    font-size: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .brief {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #111;
  }
}