.p-orderdta {
  //padding-top: $bodyPaddingTop;
  .order-details {
    padding: 15px;

    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }

    .wide-line {
      padding-top: 15px;
      width: 100vw;
      height: 10px;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #f5f5f5;
    }

    .top {
      margin-bottom: 15px;

      li {
        font-size: 1.4rem;
        margin-bottom: 15px;
      }

      .summary {
        font-weight: 600;
      }

      .date {
        font-size: 1.2rem;
        color: $color-gray2;
      }

      span.amount {
        font-size: 1.6rem;
        color: #fa303c;
      }

      .activate-checkbox {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        color: #999;
        font-weight: normal;

        input {
          margin-top: -2px;
          margin-right: 10px;
        }
      }

      .input-tit {
        color: #c00;
        font-size: 12px;
        margin-bottom: 0;
      }

      .continue-btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 1.6rem;
        background-color: $color-main;
        color: #fff;
      }

    }

    .add-info {
      ul {
        margin-top: 15px;
      }

      li {
        line-height: 1.86;
        margin-bottom: 15px;
        font-size: 1.4rem;
        color: $color-black;
        font-family: $font-sans-serif;
      }
    ;

      .shipping {
        font-weight: 600;
      }
    }

    .shipping-info {
      margin-bottom: 15px;

      ul {
        margin-top: 15px;
      }

      li {
        font-size: 1.4rem;
        margin-bottom: 15px;
      }

      .title {
        font-weight: 600;
      }

      .view-info {
        color: #1788EB;
      }
    }

    .tips {
      padding: 10px 15px;
      height: 100%;
      background-color: #f9f9f9;
      border: solid 1px #979797 !important;
      border-radius: 4px;
      font-size: 1.2rem;
      margin-bottom: 15px;

      .tips-content {
        color: $color-black;
        padding: 0 15px;
      }
    }
  }
}

.btnShowTrackingModal {
  text-decoration: underline;
}

#tracking-modal {
  .content {
    font-size: 14px;

    .summary {
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 8px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;

        .key {
          color: #666;
          text-transform: uppercase;
        }

        &.i-status {
          .val {
            font-weight: bold;
          }
        }
      }
    }

    .list {
      position: relative;
      margin-top: 20px;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10%;
        height: 36px;
        background-color: #fff;
      }

      .item {
        display: flex;

        .left {
          position: relative;
          flex: none;
          display: flex;
          justify-content: center;
          width: 14%;

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 6px;
            left: 50%;
            width: 1px;
            height: 100%;
            background-color: #ccc;
          }

          &:before {
            content: ' ';
            display: block;
            width: 9px;
            height: 9px;
            margin-top: 3px;
            border-radius: 50%;
            background-color: #ccc;
            z-index: 5;
          }

        }

        .right {

          .status {
            font-weight: bold;
          }

          .address {

          }

          .detail {
            font-size: 12px;
            color: #666;
          }

          .time {
            margin-bottom: 30px;
            font-size: 12px;
            color: #666;
            font-style: italic;
          }
        }

        &:first-of-type {
          .left {
            &:before {
              width: 11px;
              height: 11px;
              background-color: #f92f2f;
            }
          }
        }
      }
    }
  }
}