.p-reviews{
  .review-box {
    .list-wrap {
      background-color: #fff;
    }
    h2 {
      padding:0 15px;
      font-size: 1.6rem;
      line-height: 50px;
      font-weight: bold;
      text-align: center;
    }
    .avg-star {
      margin-top: 16px;
    }

    li{
      padding:15px;
      border-bottom:1px solid #e5e5e5;
    }

    span.username {
      display: inline-block;
      margin-left: 10px;
      font-size: 1.4rem;
      line-height: 30px;
    }

    h3 {
      margin: 0;
      font-size: 1.4rem;
      line-height: 30px;
      font-weight: bold;
    }

    p.content {
      font-size: 1.2rem;
      line-height: 24px;
    }

    .see-all {
      font-size: 1.4rem;
      line-height: 50px;
      font-weight: bold;
      text-align: center;
      padding: 0 15px;
      border-bottom: 1px solid #e5e5e5;
      i {
        display: inline-block;
        margin-top: 20px;
        margin-left: 10px;
        width: 14px;
        height: 9px;
        background-position: -250px -200px;
      }
    }
    .more {
      i{
        transform: rotate(-180deg);
      }
    }

    .btn-write {
      width: 100%;
      margin-top: 15px;
      background-color: $color-main;
      font-size: 1.4rem;
      line-height: 40px;
      color: #fff;
      i {
        display: inline-block;
        width: 27px;
        height: 17px;
        background-position: -350px -50px;
        vertical-align: middle;
      }
    }


  }
}