/*
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*/


[class^="ri-"], [class*="ri-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -.075em;
}

.ri-xxs {
  font-size: .5em;
}

.ri-xs {
  font-size: .75em;
}

.ri-sm {
  font-size: .875em
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-menu-line:before { content: "\ef3e"; }
.ri-search-line:before { content: "\f0d1"; }
.ri-eye-fill:before { content: "\ecb4"; }
.ri-thumb-up-fill:before { content: "\f206"; }
.ri-share-forward-box-line:before { content: "\f0fb"; }
.ri-facebook-circle-fill:before { content: "\ecba"; }
.ri-pinterest-fill:before { content: "\efff"; }
.ri-whatsapp-fill:before { content: "\f2bb"; }
.ri-twitter-x-line:before { content: "\f3e7"; }
.ri-heart-line:before { content: "\ee0f"; }
.ri-star-line:before { content: "\f18b"; }
.ri-star-fill:before { content: "\f186"; }
.ri-star-half-fill:before { content: "\f187"; }
.ri-close-circle-line:before { content: "\eb97"; }
.ri-close-line:before { content: "\eb99"; }
.ri-delete-bin-line:before { content: "\ec2a"; }
.ri-subtract-line:before { content: "\f1af"; }
.ri-add-line:before { content: "\ea13"; }
.ri-checkbox-circle-line:before { content: "\eb81"; }
.ri-gallery-upload-fill:before { content: "\eda6"; }
.ri-upload-2-line:before { content: "\f24a"; }
.ri-checkbox-blank-circle-line:before { content: "\eb7d"; }
.ri-question-fill:before { content: "\f044"; }
.ri-alert-fill:before { content: "\ea20"; }
.ri-add-circle-line:before { content: "\ea11"; }
.ri-indeterminate-circle-line:before { content: "\ee57"; }
.ri-download-fill:before { content: "\ec59"; }
.ri-user-line:before { content: "\f264"; }
.ri-checkbox-blank-circle-fill:before { content: "\eb7c"; }
.ri-checkbox-circle-fill:before { content: "\eb80"; }
.ri-edit-box-line:before { content: "\ec82"; }
.ri-edit-box-fill:before { content: "\ec81"; }
.ri-settings-3-fill:before { content: "\f0e5"; }
.ri-checkbox-fill:before { content: "\eb82"; }
.ri-checkbox-line:before { content: "\eb85"; }
.ri-information-line:before { content: "\ee59"; }
.ri-information-fill:before { content: "\ee58"; }
.ri-home-2-line:before { content: "\ee19"; }
.ri-home-4-line:before { content: "\ee1d"; }
.ri-arrow-left-line:before { content: "\ea60"; }
.ri-arrow-left-s-line:before { content: "\ea64"; }