//footer

footer{
  margin-top: 30px;
  padding-bottom: 10px;
  background-color: #f5f5f7;

  .blog-list {
    padding: 0 10px;
    .item {
      border-bottom: 1px solid #dbdbdb;
      &:nth-last-of-type {
        border-bottom: none;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #111;
        .icon {
          width: 14px;
          height: 14px;
          background-image: url(#{$img-path}global/ico_Unfold.png);
          background-size: cover;
        }
      }

      .sub-list {
        display: none;
        padding-bottom: 15px;
        .sub-item {
          padding: 0 20px;
          font-size: 14px;
          line-height: 26px;
          color: #86868a;
        }
      }

      &.open {
        .title {
          .icon {
            background-image: url(#{$img-path}global/ico_away.png);
          }
        }
        .sub-list {
          display: block;
        }
      }


    }
  }

  .tool-box {
    display: flex;
    align-items: center;
    height: 76px;
    padding-top: 10px;
    padding-left: 30px;
    background-color: #fff;
    .item {
      display: flex;
      align-items: flex-end;
      max-width: 45%;
      overflow: hidden;
      .icon {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
      .text {
        display: flex;
        font-size: 14px;
        line-height: 14px;
        span{
          display: inline-block;
          &:first-child {
            margin-right: 3px;
            border-bottom: 1px solid #111;
          }
          &:nth-child(2) {
            font-weight: bold;
          }
        }
        .currency-select {
          width: auto;
          height: 14px;
          padding: 0;
          margin: 0;
          background-color: transparent;
          border: none;
          font-weight: bold;
          color: #111;
        }
      }

      &:first-child {
        margin-right: 30px;
      }
    }
  }

  .follow-us {
    padding-top:20px;
    .f-title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    a{
      display: block;
      width:34px;
      height:34px;
      margin: 0 12px;
      .icon {
        width: 34px;
        height: 34px;
      }
    }
  }

  .news-letter{
    h2 {
      margin-top: 20px;
      font-size:16px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }

    .ipt-box{
      display: flex;
      width: 282px;
      margin: 10px auto;
      background-color: #fff;
      input {
        display: block;
        width: 245px;
        height: 36px;
        border:none;
        font-size: 12px;
      }
      .btn-submit {
        display: flex;
        align-items: center;
        width: 36px;
        height: 36px;
        .icon {
          display: block;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .language-box {
    display: none;
    .lang-title {
      font-size:1.4rem;
      font-weight: bold;
      color: #111;
      text-transform: capitalize;
    }
  }
  @at-root .custom-translate {
    .language-box{
      display: block;
    }
  }

  #lcqualitybadge {
      text-align: center;
      height: 0;
      svg {
          margin: 15px 15px 5px 15px;
      }
      img {
          width: 0;
          height: 0;
      }
  }

  .pay-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 40px;

    img {
      width: 264px;
      height: 26px;
    }
  }
  .pay-cards {
    .row{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      img {
        width: 58px;
        height: 32px;
        margin: 0 5px;
      }
    }

    .others {
      img {
        width:58px;
        height: 26px
      }
    }


  }


  .copyright{
    margin-top: 20px;
    margin-bottom: 22px;
    font-size: 1.2rem;
    text-align: center;
    color: #999;
  }

}


.fixed-tool-box-right {
  position: fixed;
  left:0;
  bottom:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index:$z1;
  .btn{
    margin-top: 15px;
  }
  .btn-poster {
    img {
      width: 64px;
      height: 76px;
    }
  }
}


// google translate
body.custom-translate {
  top: 0 !important;
  #google_translate_element {
    div {
      display: block;
      width: 75px;
      overflow: hidden;
    }
  }
  .skiptranslate.goog-te-banner-frame {
    display: none !important;
    height: 0 !important;
  }

  .skiptranslate.goog-te-gadget {
    color: transparent;
  }

  .skiptranslate.goog-te-gadget select {
    width: auto;
    height: 14px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #111;
  }

  .skiptranslate.goog-te-gadget >span {
    display: inline-block;
    width: 0;
    height: 0;
    overflow: hidden;
  }

}
