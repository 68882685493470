// modal base style

.modal-open .modal.modal-common.in {
  display: flex !important;
}

.modal {

  .m-common-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, .1);

    .text {
      width: 100%;
      padding-left: 20px;
      font-size: 18px;
      font-weight: bold;
    }

    .btn-close {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      border-left: 1px solid #e9e9e9;

      i {
        display: block;
        width: 20px;
        height: 20px;
        background: url(#{$img-path}global/ico_close-modal@3x.png) no-repeat center center;
        background-size: contain;
      }
    }
  }

  &.modal-common {
    align-items: center;
    justify-content: center;
    transition: none;

    .modal-dialog {
      width: 87%;
      transition: none;
      transform: none;
    }

    .modal-content {
      border-radius: 0;
      border: none;
    }

    .modal-body {
      padding: 6%;
    }

    .btn-modal-close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 41px;
      height: 41px;
      background: url('../images/global/ico_close.png') 0 0;
      opacity: 1;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 18px;
      opacity: 1;

      &.icon-s {
        background-position: 0 -50px;
      }
    }
  }

  &.modal-fullpage {
    .modal-dialog {
      position: absolute;
      width: 100%;
      overflow: auto;
      margin: 0;
      top: 0;
      bottom: 0;
    }

    .modal-content {
      height: 100vh;
      padding: 70px 15px;
      background-color: #fff;
      border-radius: 0;
      border: none;
      overflow: auto;

      .m-common-title {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        z-index: 10;
        width: 100%;
        background-color: #fff;
      }

    }

    .modal-header {
      margin: 0 -15px;
      padding: 0;
      height: 54px;
      border-bottom: 1px solid #e5e5e5;

      .back {
        display: block;
        position: relative;
        width: 60px;
        height: 54px;

        i {
          display: block;
          position: absolute;
          top: 17px;
          left: 20px;
          background-position: -350px -300px;
        }
      }
    }

    &.fade {
      .modal-dialog {
        transform: translate(0, 100%);
      }
    }

    &.in {
      .modal-dialog {
        transform: translate(0, 0);
      }
    }
  }
}


// toast modal

#toastModal {

  &.in {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      margin: 0;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 50%;
      max-width: 90%;
      padding: 30px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, .9);
      box-shadow: none;

      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 30px;
      }

      .text {
        font-size: 16px;
        text-align: center;
      }
    }

  }
}


.commonTipsModal {

  .modal-dialog {
    position: absolute;
    width: 100%;
    overflow: auto;
    margin: 0;
    top: 0;
    bottom: 0;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    height: 100vh;
    padding: 0 15px;
    background-color: #fff;
    overflow: auto;
  }

  .modal-header {
    margin: 0 -15px;
    padding: 0;
    height: 54px;
    border-bottom: 1px solid #e5e5e5;

    .back {
      display: block;
      position: relative;
      width: 60px;
      height: 54px;

      i {
        display: block;
        position: absolute;
        top: 17px;
        left: 20px;
        background-position: -350px -300px;

      }
    }
  }

  &.fade {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  .title {
    margin-top: 15px;
    font-family: $font-serif;
    font-size: 18px;
    text-align: center;
    letter-spacing: 2px;
  }

  .brief {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: #111;
  }
}


#loadingModal {

  &.in {
    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
    }

    .modal-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50vw;
      height: 50vw;
      background-color: rgba(0, 0, 0, .75);
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      outline: 0;
      box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

      .cssload-jumping {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          height: 9px;
          width: 9px;
          margin: 0 3px;
          background: #aaa;
          border-radius: 50%;
        }

        span:nth-child(1) {
          animation: scale 0.85s 0.085s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }

        span:nth-child(2) {
          animation: scale 0.85s 0.17s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }

        span:nth-child(3) {
          animation: scale 0.85s 0.255s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }

        span:nth-child(4) {
          animation: scale 0.85s 0.34s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }

        span:nth-child(5) {
          animation: scale 0.85s 0.425s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }


        @keyframes scale {
          0% {
            transform: scale(0);
          }
          25% {
            transform: scale(0.9, 0.9);
            background: rgb(181, 170, 181);
          }
          50% {
            transform: scale(1, 1);
            margin: 0 2px;
            background: rgb(5, 5, 5);
          }
          100% {
            transform: scale(0);
          }
        }
      }

    }
  }
}

#salePosterModal {

  .modal-dialog {
    width: 90%;
    margin: auto;
  }

  .modal-content {
    position: relative;
    background: none;
    box-shadow: none;
    border: none;
  }

  .close {
    position: absolute;
    background: none;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 10;
  }

  .wrap {
    position: relative;
  }

  .img-wrap {
    display: block;

    img {
      width: 100%;
    }
  }

  .discountText {
    position: absolute;
    top: 48vw;
    width: 100%;
    font-size: 9vw;
    font-weight: bold;
    color: #ec1717;
    text-align: center;
  }

}

#fromShareModal {
  .modal-dialog {
    width: 328px !important;
  }

  .modal-content {
    padding: 30px 20px 40px;
    border: none;
  }

  .m-inner {
    font-size: 14px;
    color: #111111;
    text-align: center;

    .m-title {
      font-size: 18px;
      font-weight: bold;
    }

    .line1 {
      margin-top: 20px;
    }

    .line2 {
      .em {
        font-weight: bold;
        color: #fa303c;
      }
    }

    .code-box {
      width: 223px;
      height: 98px;
      margin: 20px auto;
      background: url("#{$img-path}reward/img_codeb@2x.png");
      background-size: contain;
      overflow: hidden;

      .c-l-1 {
        margin-top: 10px;
        font-size: 12px;
        color: #fff;
      }

      .c-l-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        margin-top: 5px;
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .btn-copy {
        margin-top: 2px;
        font-size: 12px;
        color: #0067cc;
        font-weight: bold;
      }
    }

    // .line3 {

    // }
    .line4 {
      font-size: 12px;
      color: #86868a;
    }

  }
}

#shareModal {
  .modal-dialog {
    position: absolute;
    width: 100%;
    overflow: auto;
    margin: 0;
    bottom: 0;
    background-color: #fff;
  }

  .modal-content {
    position: relative;
    border-radius: 0;
    border: none;
    height: 100%;
    overflow: auto;
  }

  &.fade {
    .modal-dialog {
      transform: translate(0, 100%);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  .m-common-title {
    margin-left: 0;
    margin-right: 0;
  }

  .content {
    padding: 5px 15px;
  }

  .js-share {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 21%;
      justify-content: space-between;
      margin:10px auto;

      .js-share-link, .js-share-whatsapp {
        .name {
          margin-top:-5px;
        }
      }

      .icomoon {
        font-size: 30px;
      }

      .icon-pinterest {
        color: #E52A20;
      }

      .icon-facebook {
        color: #4B64AE;
      }

      .icon-twitter-x {
        color: #000;
      }

      .icon-mail4 {
        color: #5296E0;
      }

      .icon-whatsapp {
        font-size:35px;
      }

      .icon-link-circle {
        color: #F76793;
        font-size:35px;
      }

      img {
        width: 40px;
        height: 40px;
      }

      .name {
        font-size: 12px;
        color:#666;
      }
    }
  }
}


#CookiesUsageModal {
  &.modal-fullpage {
    .modal-dialog {
      top: auto;
    }

    .modal-content {
      height: auto;
      padding: 20px;
    }
  }

  .content {
    .title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .text {
      font-size: 14px;

      a {
        text-decoration: underline;
      }
    }

    .btn_allowAll,
    .btn_acceptNecessary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      margin: 15px auto;
      background-color: #0a6328;
      font-size: 16px;
      color: #fff;

    }
  }
}

