//product css
.p-prod {
  //padding-top: $bodyPaddingTop;
  //background-color: #f6f6f6;
  background: #f2f2f2;

  $positioning_bar_height: 40px;

  .product-main {
    padding: 0 10px 10px;
  }

  .positioning-bar {
    position: fixed;
    top: -$positioning_bar_height;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8;
    transition: top .3s;
    z-index: 2;

    &.fixed {
      top: 0;

      &.under_header {
        top: 54px;
      }
    }

    .item {
      display: flex;
      justify-content: center;
      width: 33.3333%;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $positioning_bar_height;
        border-bottom: 2px solid transparent;
        margin-bottom: -1px;
        font-size: 14px;
        font-weight: bold;
      }

      &.on {
        .text {
          border-color: #111;
        }
      }
    }
  }

  .prod-slider {
    position: relative;
    padding: 0;
    background-color: #fff;
    border: none;
    box-shadow: none;
    width: 100%;
    aspect-ratio: 375/600;

    .swiper-slide img {
      width: 100%;
      aspect-ratio: 375/600;
    }

    .swiper-pagination-fraction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 52px;
      height: 22px;
      overflow: hidden;
      top: 10px;
      left: auto;
      right: 5%;
      border-radius: 22px;
      background-color: rgba(10, 10, 10, 0.3);
      color: #fff;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 22px;
      }

      .cur {
        border-bottom-right-radius: 12px;
        background-color: rgba(10, 10, 10, 0.2);
        font-size: 12px;
      }

      .total {
        font-size: 10px;
      }

    }

    .video-cover-image {
      .btn-wrap {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        // z-index: 10;

        .btn-play {
          width: 60px;
          height: 60px;
          pointer-events: auto;
        }
      }
    }

    .big-video-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      .btn-close-video {
        position: absolute;
        right: -50px;
        top: -50px;
        flex: none;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100px;
        height: 100px;
        padding: 20px;
        border-radius: 50%;
        border: 1px solid #999;
        background-color: rgba(255, 255, 255, .5);
        z-index: 10;

        i {
          display: block;
          width: 20px;
          height: 20px;
          background: url(#{$img-path}global/ico_close-modal@3x.png) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  .prod-block {
    background: #fff;
    padding: 10px;
    margin: 10px auto;
  }

  .prod-intro {
    position: relative;

    .product-intro-container {
      background-color: #fff;
    }

    .price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .price {
        display: flex;
        align-items: flex-end;
      }

      .prod-price {
        display: flex;
        align-items: flex-start;
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        color: $color-red2;
        text-transform: uppercase;

        .price-symbol {
          margin-right: 1px;
          font-size: 14px;
          font-weight: normal;
          color: #666;
        }
      }

      .market-price {
        display: flex;
        margin-left: 10px;
        font-size: 1.2rem;
        color: #86868a;
        font-weight: normal;
        text-decoration: line-through;
      }

      .discount {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 20px;
        margin-left: 10px;
        border: solid 1px #ff4b4b;
        font-size: 12px;
        color: #111111;
      }

      .btn-share {
        display: flex;
        align-items: flex-end;
        font-size: 12px;
        color: #999;
        font-weight: bold;
        text-transform: capitalize;

        em {
          display: block;
          width: 20px;
          height: 20px;
          margin-left: 3px;
          background: url(#{$img-path}product/ico_share@2x.png) no-repeat center;
          background-size: contain;
        }
      }
    }

    .prod-title {
      margin-top: 10px;
      font-size: 1.4rem;
      color: #111;
      line-height: 21px;
      @include multi-line(2);

      .mark-item {
        display: inline-block;
        height: 16px;
        background: url(#{$img-path}category/img_mark-icon.png) 0 0 no-repeat;
        background-size: 350px 50px;
        vertical-align: top;

        &.new {
          width: 32px;
          background-position: -50px 0;
        }

        &.hot {
          width: 28px;
          background-position: -100px 0;
        }
      }
    }

    .code-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 7px;

      .code {
        font-size: 1.4rem;
        color: #86868a;
      }

      .comment-summary {
        display: flex;

        .icon-s {
          background-position: 0 -197px;
        }

        .summary {
          color: #111;
          font-size: 1.2rem;
          line-height: 2.1rem;
        }
      }
    }

    .hot-tags {
      display: flex;
      flex-wrap: wrap;

      .tag-item {
        display: flex;
        align-items: center;
        height: 24px;
        margin: 15px 10px 0 0;
        border-radius: 0 12px 0 12px;
        font-size: 12px;

        i {
          display:flex;
          justify-content: center;
          color: #fff;
          width: 28px;
          height: 24px;
          font-size: 16px;
          border-radius: 0 12px 0 12px;
        }

        i.icon-fast-shipping{
          background: #55ddee;
        }

        i.ri-star-line {
          background:#69c89e;
        }

        &.new {
          background-color: #e1f9eb;
        }

        &.hot {
          background-color: #eec03f;
        }

        &.free_shipping {
          background-color: #aaeeee;
        }

        .text {
          padding: 0 8px 0 6px;
        }
      }


    }

    .qty {
      margin-top: 20px;
      font-size: 1.4rem;
      line-height: 24px;
      font-weight: bold;
      color: #666;
      text-transform: uppercase;

      input {
        display: inline-block;
        width: 60px;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        font-size: 1.6rem;
        line-height: 15px;
        text-align: center;
      }
    }

    .btn-add,
    .btn-minus {
      display: inline-block;
      width: 15px;
      height: 16px;
      vertical-align: middle;
    }

    .btn-add {
      background-position: -250px 0;
    }

    .btn-minus {
      margin-left: 20px;
      background-position: -300px 0;
    }

  }

  .promotion-box {
    $countdownWidth: 80px;

    margin: 10px auto 15px;
    position: relative;

    .info {
      padding: 2px $countdownWidth+10px 2px 15px;
      background-color: $color-main;
      border-radius: 24px;

      .message {
        font-size: 1.4rem;
        color: #fff;
      }

      .countdown {
        position: absolute;
        top: 0;
        right: 6px;
        bottom: 0;
        width: $countdownWidth;
        height: 100%;

        i {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-top: 2px;
          margin-right: 5px;
          background-position: -350px -600px;
        }

        .time {
          display: inline-block;
          width: 50px;
          font-size: 1.4rem;
          color: #fff;
          line-height: 20px;
        }
      }
    }
  }

  .prod-activity {
    .item > .a-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 14px;
      margin-top: 6px;
      background-color: #f5edea;
      border-radius: 18px;
      font-size: 14px;
      line-height: 18px;
      color: #111;
      //.desc {
      //  @include one-line-text;
      //}
      .clock {
        display: flex;
        align-items: center;
        font-size: 12px;

        span {
          display: inline-block;
          min-width: 17px;
          text-align: center;
        }
      }
    }
  }

  .stock-all-spec-tips {
    width: 100%;
    margin-top: 5px;
    border-bottom: 1px solid #ddd;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .multi-select-tab-box {
    display: flex;
    margin: 0px -7px -10px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #f2f2f2;

    .tab-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      color: #666;

      &.cur {
        background-color: #fff;
        border-color: #ee7474;
        color: #000;

        &.normal:after,
        &.normal:before,
        &.in-stock:after,
        &.in-stock:before {
          display: block;
        }

      }

      &.normal:after,
      &.in-stock:before {
        content: " ";
        position: absolute;
        top: 0;
        z-index: 1;
        display: none;
        width: 10px;
        height: 100%;
        background: #fff;
      }

      &.normal:before,
      &.in-stock:after {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 2;
        display: none;
        width: 16px;
        height: 4px;
        background-color: #ee7474;
        transform: translateX(-50%);
      }

      &.normal:after {
        right: -6px;
        border-top-right-radius: 5px;
        box-shadow: 1px 0 1px 0 rgba(0, 0, 0, .1);
        transform: skew(8deg);
      }

      &.in-stock:before {
        left: -6px;
        border-top-left-radius: 5px;
        box-shadow: -1px 0 1px 0 rgba(0, 0, 0, .1);
        transform: skew(-8deg);
      }
    }
  }

  .product-services {
    h2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 1.4rem;
      color: #111;
      line-height: 24px;

      .title {
        overflow-x: auto;
        font-weight: bold;
        text-transform: capitalize;

        .custom-size-service-fee {
          font-weight: normal;
          font-size: 13px;
        }
      }
    }


    .value-added {
      .item {
        font-size: 13px;
        line-height: 24px;

        label {
          float: left;
          position: relative;
          padding-left: 20px;
          font-weight: normal;

          input {
            position: absolute;
            top: 0;
            left: 0;
          }

          .item-name {
            font-weight: bold;
          }
        }

        .btn_model {
          float: right;
          display: block;
          text-decoration: underline;
        }
      }
    }
  }

  .prod-detail {
    padding-top: 1px;
    padding-bottom: 5px;

    .toggle-container-box.eta-box {
      border: none;
    }

    .toggle-container-box {
      border-top: 1px solid rgba(92, 60, 48, 0.2);
    }

    .toggle-container-header {
      position: relative;
    }

    .toggle-container-title {
      p {
        margin-bottom: 0;
        font-size: 1.4rem;
        align-items: center;
        display: flex;
        height: 48px;
        line-height: 48px;
      }

      .eta-date-range {
        padding-left: 5px;
      }
    }

    .toggle-btn {
      font-size: 0;
      position: absolute;
      right: 5px;
      top: 10px;

      .icon-arrow-down-s-line {
        font-size: 26px;
        display: block;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transition: transform 0.3s linear;
      }

      .icon-arrow-down-s-line.open {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
      }
    }

    .toggle-container-content {
      font-size: 12px;
      padding-bottom: 10px;

      a {
        color: #ee7474;
        text-decoration: underline;
      }

      .in-stock .time {
        font-weight: bold;
        color: #ee7474;
      }
    }

    .spec {
      span {
        display: inline-block;
        line-height: 18px;
      }

      .key {
        width: 45%;
        overflow: hidden;
      }

      .value {
        width: 53%;
        word-wrap: normal;
        overflow: hidden;
      }
    }

    .content {
      display: block;
      font-size: 12px;
      line-height: 24px;

      h1 {
        margin: 10px 0;
        font-size: 16px;
        font-weight: bold;
      }

      h2 {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;

        &:before {
          display: none;
        }
      }

      h3 {
        margin: 10px 0;
        font-size: 12px;
        font-weight: bold;
      }

      .tips {
        color: #666;
      }

      &.in-stock .time {
        font-weight: bold;
        color: #ee7474;
      }
    }
  }

  .review-box {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.6rem;
      line-height: 24px;
      font-weight: bold;
    }

    .btn-write {
      font-size: 14px;
      line-height: 24px;
      color: #111;
      text-transform: capitalize;
      text-decoration: underline;
    }

    //.avg-star {
    //  margin-top: 16px;
    //}

    .list-wrap {
      margin-top: 15px;
      //background-color: #fff;
    }

    li {
      padding: 15px 0;
      border-top: 1px solid #e5e5e5;

      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .username {
      margin-right: 16px;
      font-size: 1.4rem;
      font-weight: bold;
    }

    .date {
      margin-top: 5px;
      font-size: 12px;
      color: #86868a;
    }

    h3 {
      margin: 0;
      font-size: 1.4rem;
      line-height: 30px;
      font-weight: bold;
    }

    .comment-image {
      display: flex;
      margin: 10px auto;

      img {
        width: 30%;
        margin: 0 6px;
        pointer-events: auto;
        user-select: auto;
      }
    }

    p.content {
      margin-top: 10px;
      font-size: 1.4rem;
      line-height: 18px;
    }

    .see-all {
      margin-top: 15px;
      font-size: 1.4rem;
      text-align: center;
      text-decoration: underline;
    }

    .more {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  .questions-box {
    .questions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 1.6rem;
        line-height: 24px;
        font-weight: bold;
      }

      .btn-write {
        font-size: 1.4rem;
        line-height: 24px;
        color: #111;
        text-transform: capitalize;
        text-decoration: underline;
      }
    }

    .list-wrap {
      .question-and-answer {
        border-bottom: 1px solid #ccc;
        margin: 10px auto;
        padding-bottom: 10px;

        dl {
          font-size: 1.2rem;
          display: flex;
          margin: 0;

          dt {
            font-weight: bold;
            margin-right: 5px;
            font-family: sans-serif;
          }

          dd {
            line-height: 20px;
            margin-bottom: 8px;
            overflow: hidden;
            padding-left: 5px;
            text-overflow: ellipsis;
            word-break: break-word;

            p {
              margin: 5px auto;
            }
          }
        }

        .answer-images {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 10px;

          img {
            width: 30%;
            margin: 0 6px;
            pointer-events: auto;
            user-select: auto;
          }
        }
      }

      .answer-bottom {
        display: flex;
        flex-flow: column;
        font-size: 1.2rem;
        justify-content: space-between;
        width: 100%;
        color: #999;

        .answer-item-by {
          padding-left: 5px;
        }

        .answer-item-help {
          text-align: right;

          .text {
            margin-right: 5px;
          }
        }
      }
    }

    .not-found-tips {
      font-size: 1.2rem;
      line-height: 16px;

      span.submit-question {
        color: #FF7176;
        text-decoration: underline;
      }
    }


    .faq-container-no-question {
      font-size: 14px;
      padding: 10px 0;
      line-height: 16px;
      text-align: center;

      .submit-question {
        margin-top: 10px;
        text-decoration: underline;
      }
    }
  }


  footer {
    margin-top: 0;
  }

  .fixed-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 1;

    .new-user-fixed {
      position: static;
    }

    .add-cart-box {
      display: flex;
      padding: 6px;
      background-color: #fcfcfc;
      //border:1px solid #e9e9e9;
      box-shadow: 0 -3px 6px rgba(150, 150, 150, .2);

      .btn-add-cart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 41px;
        margin-left: 10px;
        background-color: #f77a7a;
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
      }
    }

    .tool-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: none;
      padding: 0 12px;

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        margin-top: 1px;
        font-size: 12px;
        color: #333;
        text-transform: capitalize;
      }
    }

    .btn-like {
      min-width: 58px;

      .icon-s {
        display: block;
        width: 24px;
        height: 20px;
        background-position: -350px -100px;
      }

      // .count {
      //   //margin-left: 2px;
      //   //min-width: 15px;
      // }
      &.liked {
        .icon-s {
          background-position: -300px -100px;
        }
      }
    }

    .btn-share {
      .icon-s {
        background-position-x: -95px;
        background-position-y: -647px;
      }
    }

    .btn-prod-service {
      .icon-s {
        background-position: -250px -350px;
      }
    }

    .new-user-fixed {
      position: static;
    }
  }

  .icon-top {
    bottom: 170px;
  }

  .btn-service {
    bottom: 120px;
  }
}


.star {
  display: inline-block;
  width: 95px;
  height: 14px;
  line-height: 30px;

  &.level5 {
    background-position: 0 -200px;
  }

  &.level4 {
    background-position: -20px -200px;
  }

  &.level3 {
    background-position: -40px -200px;
  }

  &.level2 {
    background-position: -60px -200px;
  }

  &.level1 {
    background-position: -80px -200px;
  }

  &.level0 {
    background-position: -100px -200px;
  }
}


.recommend-list {
  margin-top: 15px;
  background-color: #fff;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 30px;

  h2 {
    margin-top: 15px;
    margin-bottom: 0;
    padding-left: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  .prod-list {
    margin-top: 10px;
  }

}

.list-bottom-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #aaa;
  line-height: 2em;

  span {
    display: block;
    margin: 0 10px;
    color: #333;
  }

  em {
    display: block;
    width: 20px;
    height: 1px;
    background-color: #aaa;
  }
}

.prod-select {
  //background: #f5f5f5;
  padding: 0 15px;
  margin: 10px -15px;

  .content {
    //padding-top: 15px;
    //padding-bottom: 15px;
    overflow: hidden;

    .select-box {
      margin-top: 20px;

      h2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 1.4rem;
        color: #111;
        line-height: 24px;

        .title {
          overflow-x: auto;
          font-weight: bold;
          text-transform: capitalize;
          text-wrap: nowrap;

          .custom-size-service-fee {
            font-weight: normal;
            font-size: 13px;
          }
        }


        .selected {
          font-weight: normal;
          text-transform: capitalize;
        }

        .more {
          flex: none;
          margin-left: 10px;
          font-size: 1.4rem;
          color: #111;
          text-decoration: underline;

          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }
    }

    .color-list-wrap {
      position: relative;
      margin: 0 -5px;

      &.limit {
        max-height: 127px;
        overflow: hidden;
      }

      .btn-more {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 42px;
        overflow: hidden;
        background: url(#{$img-path}product/ico_unfold.jpg) no-repeat center;
        background-size: 60px 52px;
      }
    }

    .color-list {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        margin: 5px;
        display: inline-block;

        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 32px;
          border-radius: 32px;

          &.As_Shown {
            font-size: 14px;
            text-indent: -9999px;
            background: url(#{$img-path}product/ico_aspicture.png) no-repeat center;
            background-size: 40px 32px;
          }
        }

        i {
          display: none;
          position: absolute;
          width: 20px;
          height: 15px;
          top: 10px;
          //left: 11px;
          background-position: -300px -300px;
        }

        &.selected {
          i {
            display: block;
          }

          .As_Shown {
            //background-color: #ee7474;
            //border:1px solid #ee7474;
            color: #fff;
          }
        }

        &.empty-li {
          margin: 0 5px;
          display: block;
          width: 40px;
          height: 0;
        }
      }
    }

    .select-thumb {
      .thumb-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0 -10px;

        li {
          position: relative;
          width: 54px;
          height: 85px;
          margin: 0 10px 20px 10px;
          border: 1px solid transparent;
          cursor: pointer;

          img {
            width: 52px;
            height: 83px;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 83px;

            i {
              display: block;
              width: 20px;
              height: 15px;
              background-position: -300px -300px;
            }
          }

          &.selected {
            border-color: #111;

            .mask {
              display: flex;
            }

          }

          &.empty-li {
            margin: 0 10px;
            display: block;
            width: 50px;
            height: 0;
          }
        }
      }
    }

    .select-size {
      .size-list-wrap {
        margin: 0 -3px;

        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            width: 61px;
            height: 32px;
            margin: 0 3px 10px;
            border: solid 1px #999999;
            background-color: #ffffff;
            font-size: 12px;
            color: #111111;

            &.selected {
              color: #ffffff;
              border: solid 1px #f77a7a;
              background-color: #f77a7a;
            }

            &.empty-li {
              height: 0;
              border: none;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .size-detail {
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #f5f5f7;

      .size-detail-inner {
        display: flex;
        align-items: flex-start;
        font-size: 1.2rem;
        color: #111111;
      }

      .inch {
        width: 50%;
        padding-right: 20px;
      }

      .cm {
        width: 50%;
        padding-left: 20px;
      }

      .title {
        margin-bottom: 10px;
        font-weight: bold;
        text-transform: uppercase;

        .custom-size-service-fee {
          margin-left: 10px;
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
      }

      span {
        line-height: 1.6rem;
      }

      .size-detail-tips {
        margin-top: 10px;
        font-size: 12px;
        color: #666;

        .text2 {
          display: none;
          color: #111;
        }

        .btn_more {
          text-decoration: underline;
        }
      }
    }

    .size_tips {
      margin-top: 15px;
      font-size: 14px;
      font-weight: bold;
      color: #111111;
    }

    .in-stock-tips {
      margin-top: 5px;
      font-size: 12px;
      font-weight: bold;
      color: #ee7474;
    }

    .custom-size-button {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .custom-item {
      width: 129px;
      height: 32px;
      //margin: 5px;
      border: solid 1px #999999;
      background-color: #ffffff;
      font-size: 12px;
      text-transform: capitalize;

      &.selected {
        color: #ffffff;
        border: solid 1px #f77a7a;
        background-color: #f77a7a;
      }
    }

    .value-added {
      .item {
        font-size: 13px;
        line-height: 24px;

        label {
          float: left;
          position: relative;
          padding-left: 20px;
          font-weight: normal;

          input {
            position: absolute;
            top: 0;
            left: 0;
          }

          .item-name {
            font-weight: bold;
          }
        }

        .btn_model {
          float: right;
          display: block;
          text-decoration: underline;
        }
      }
    }

    .tips {
      margin-top: 10px;
      padding: 10px;
      overflow: auto;
      border-radius: 4px;
      background-color: #f9f9f9;
      appearance: none;
      font-size: 1.2rem;
      resize: none;
      width: 100%;
      height: 80px;

      &:focus {
        border-color: $color-black;
      }
    }

  }

  .add-cart {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    padding: 0 15px;

    .btn {
      margin-top: 10px;
      display: block;
      width: 100%;
      background-color: $color-red1;
      font-size: 1.6rem;
      line-height: 50px;
      color: #fff;
      margin-bottom: 10px;
    }
  }
}

#custom-size-fee-intro-modal {
  .content {
    font-size: 1.4rem;
    line-height: 24px;

    p {
      margin: 6px auto;
    }

    th, td {
      text-align: center;
      vertical-align: middle;
    }
  }
}

#custom-modal {
  .intro {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 1.4rem;
    line-height: 24px;
    color: #999;
  }

  .switch-unit {
    margin-bottom: 15px;

    .custom-unit-trans {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 61px;
      height: 32px;
      font-size: 1.2rem;
      margin-right: 20px;
      color: #111;
      border: 1px solid #999;

      &.on {
        background-color: #111;
        color: #fff;
        border-color: #111;
      }
    }
  }

  label {
    font-size: 1.4rem;
    line-height: 24px;
    color: #111;
    font-weight: normal;

    .tips {
      color: #999;
    }
  }

  select {
    height: 50px;
    font-size: 1.4rem;
    color: #999;
    border-color: #111;

    option {
      color: #111;
    }

    &.selected {
      color: #111;
    }
  }

  .custom-size-fee {
    font-size: 14px;

    .fee {
      padding-left: 5px;
    }

    .icomoon {
      color: #999;
    }
  }

  .btn-custom-confirm {
    width: 100%;
    margin: 20px 0 30px;
    background-color: $color-main;
    font-size: 1.6rem;
    line-height: 50px;
    color: #fff;
  }

  .input-text-tips {
    margin-bottom: 10px;
    font-size: 12px;
    color: #999999;
  }

  .pregnant {
    margin-right: 10px;
  }

  .select-belly {
    display: none;
  }

  .pregnant:checked + .select-belly {
    display: block;
  }

}


#measure-guide-box {

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px -10px 0;

    .line {
      width: 100%;
      height: 1px;
      border-top: 1px solid #999;
    }

    .text {
      flex: none;
      padding: 0 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .measure-img {
    display: block;
    width: 225px;
    margin: 30px auto 0;
  }

  .section-mg {

    .title-mg {
      position: relative;
      margin: 20px 0 15px 0;
      font-size: 16px;
      line-height: 1.4;
      color: #111;
      font-weight: bold;
      text-transform: uppercase;

      &.indent {
        padding-left: 30px;

        .sn {
          position: absolute;
          top: -1px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          background-color: #fd3e60;
          padding-top: 1px;
          border-radius: 50%;
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .list-mg {
      .item-mg {
        position: relative;
        margin: 10px 0;
        padding-left: 14px;
        font-size: 14px;
        line-height: 1.4;
        color: #111;

        span.em {
          color: #e64545;
        }

        &:before {
          content: ' ';
          position: absolute;
          top: 7px;
          left: 0;
          display: block;
          width: 4px;
          height: 4px;
          background-color: #111;
          border-radius: 50%;

        }
      }
    }
  }
}

#bustle-modal {
  .content {
    h3 {
      margin-top: 20px;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2px;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .text {
      font-size: 14px;
      line-height: 24px;
    }

    .intro {
      h4 {
        margin-top: 20px;
      }

      img {
        display: block;
        margin: 10px auto;
        width: 55%;
      }
    }

    .arrow {
      display: block;
      margin: 26px auto 10px;
      width: 7%;
    }

    .section {
      padding: 15px;
      margin-bottom: 20px;
      background-color: #f7f7f7;
      border-radius: 8px;

      img {
        display: block;
        margin: 10px auto;
        width: 60%;
      }
    }

  }

}

#petticoat-modal {
  .content {
    h3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2px;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .section {
      margin-bottom: 40px;

      img {
        width: 100%;
      }
    }
  }

}

.service-modal {
  //padding: 70px 15px 15px;
  //background-color: #fff;
  //border-radius: 0;
  //border: none;
  //overflow: auto;

  .content {
    font-size: 1.4rem;
    line-height: 24px;
    padding: 10px 0;

    strong {
      font-family: sans-serif;
    }

    p {
      margin: 10px auto;
    }

    ul {
      padding: 5px 10px;
    }

    li {
      margin: 20px auto;
    }
  }
}

#addCartModal {

  &.fade {
    .modal-dialog {
      transform: translate(0, 100%);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  .modal-content {
    position: relative;
    background: #f2f2f2;
    height: 90vh;
    padding: 55px 10px 10px 10px;
    overscroll-behavior: contain;
    overflow: auto;
  }

  .modal-dialog {


  }
  .cart-item-list {
    .p-name {
      font-family: sans-serif;
      font-weight: bold;
      line-height: 24px;
    }
    .services .key{
      font-family: sans-serif;
      font-weight: bold;
      line-height: 24px;
    }
  }


  .icon_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    img {
      width: 50px;
      height: 50px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .body-wrap {
    margin-top: 6px;
    .cart-item-list {
      background: #fafafa;
    }
  }

  .money {
    color: #ee7474;
  }

  .subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-top: 10px;
    margin-bottom: 10px;

    .key {
      font-weight: bold;
    }
  }
  .freeShippingInfo {
    .tips {
      font-size: 1.2rem;
      text-align: center;
    }
    .progress-box {
      display: flex;
      align-content: space-between;
      align-items: center;
      margin-top: 10px;
      .min, .max {
        text-align: center;
        padding: 0 5px;
      }
      .progress {
        width: 70%;
        margin-bottom:0;
        height: 4px;
        .progress-bar{
          background:#121212
        }
      }
    }
  }

  .recommend-list h2{
    margin: 5px auto 10px;
    padding-left: 0;
  }

  .modal-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    padding: 10px;

    .btn-checkout {
      display: block;
      background-color: #ee7474;
      font-size: 14px;
      line-height: 40px;
      font-weight: bold;
      text-align: center;
      text-transform: capitalize;
      color: #fff;
    }

    .btn-continue {
      margin-top: 10px;
      background-color: #fff;
      font-size: 14px;
      font-weight: bold;
      color: #111;
      text-align: center;
      text-decoration: underline;
    }
  }
}

#write-review {
  .content {
    h2 {
      margin-top: 10px;
      font-family: $font-serif;
      font-size: 1.8rem;
      color: #111;
      letter-spacing: 2px;
      text-align: center;
    }
  }

  .prod-info {
    margin-top: 15px;

    > img,
    > .img {
      display: block;
      width: 26%;
      margin-right: 3%;
    }

    .info {
      width: 70%;
      font-size: 1.2rem;
      line-height: 2;
      color: #111;
    }

    .sn {
      margin-top: 10px;
      color: #999;
    }
  }

  .rating-box {
    margin-top: 15px;
    font-size: 1.2rem;
  }

  .user-name-wrap,
  .content-wrap {
    .title {
      margin-top: 15px;
      margin-bottom: 3px;
      font-size: 1.2rem;
    }
  }

  .user-name-wrap {
    input {
      height: 36px;
    }
  }

  .content-wrap {
    textarea {
      width: 100%;
      height: 136px;
      resize: none;
      overflow: auto;
      border: 1px solid #ccc;
      box-shadow: none;
      padding: 10px;

      &:focus {
        border: 1px solid $color-black;
      }
    }
  }

  .upload-file {
    margin-top: 15px;

    .thumb-wrap {
      position: relative;
      margin-left: 10px;
      width: 60px;
      height: 60px;
      overflow: hidden;

      img.thumb {
        width: 100%;
        height: 100%;
      }

      .ri-delete-bin-line {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        font-size: 1.2rem;
        text-align: center;
        line-height: 60px;
        text-transform: capitalize;
        color: #fff;
      }
    }

    // .upload-file-form {

    // }
    .btn-add-img {
      display: block;
      position: relative;
      width: 60px;
      height: 60px;
      border: 1px dashed #ccc;
      background-color: #f9f9f9;

      i {
        position: absolute;
        width: 28px;
        height: 22px;
        top: 19px;
        left: 16px;
        background-position: 0 -350px;
      }
    }
  }


  .btn-review-submit {
    width: 100%;
    margin: 20px 0;
    background-color: $color-main;
    font-size: 1.6rem;
    line-height: 50px;
    color: #fff;
  }

  .is-success {
    margin-top: 20px;
    font-size: 16px;
    color: #295e07;
    text-align: center;
  }
}

#submit-a-question {
  .input-wrap {
    .title {
      margin-top: 15px;
      margin-bottom: 3px;
      font-size: 1.2rem;
    }

    input {
      height: 36px;
    }
  }

  .content-wrap {
    .title {
      margin-top: 15px;
      margin-bottom: 3px;
      font-size: 1.2rem;
    }

    textarea {
      width: 100%;
      height: 166px;
      resize: none;
      overflow: auto;
      border: 1px solid #ccc;
      box-shadow: none;
      padding: 10px;
      font-size: 1.2rem;

      &:focus {
        border: 1px solid $color-black;
      }
    }
  }

  .btn-question-submit {
    width: 100%;
    margin: 20px 0;
    background-color: #ee7474;
    font-size: 1.6rem;
    line-height: 50px;
    color: #fff;
  }

  .success-info {
    margin-top: 12px;
    font-size: 14px;
  }
}

#attach-image-modal {
  .btn-close {
    position: absolute;
    right: 6px;
    top: 2px;
    z-index: 10;
    font-size: 20px;
    color: #000;
  }

  .modal-content {
    display: flex;
  }

  img {
    width: 100%;
    margin: 0 auto;
    pointer-events: auto;
  }
}

#size-chart {
  .measure-img {
    display: block;
    margin: 20px auto;
  }

  .chart-title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #ee7474;
    text-align: center;
    font-weight: bold;
  }

  .size-chart {
    padding: 0 12px;

    h2 {
      font-family: $font-serif;
      font-size: 1.8rem;
      text-align: center;
      color: $color-black;
      margin-top: 15px;
      letter-spacing: 2px;
      margin-bottom: 15px;
    }

    .tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .to_measurement {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    #measurementGuide {
      height: 20px;
    }

    .tab {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 32px;
        font-size: 1.2rem;
        margin-right: 20px;
        color: #111;
        border: 1px solid #999;
        text-transform: uppercase;
      }

      li:nth-of-type(2) {
        margin-right: 0;
      }

      .cur {
        background-color: #111;
        color: #fff;
        border-color: #111;
      }
    }

    .size-list {
      width: 100%;
      overflow: auto;

      .row-title {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        text-align: center;
        color: #fff;

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: none;
          margin: 0;
          height: 60px;
          background-color: #2f3435;
          padding: 10px;
          font-family: $font-sans-serif;
          font-size: 1.2rem;
          line-height: 12px;
          font-weight: normal;
          text-align: center;

          &:first-child {
            position: sticky;
            left: 0;
          }
        }
      }

      .row-list {
        text-align: center;
        padding: 0;

        .row {
          // padding: 0 7px;
          margin: 0;
          line-height: 1;

          ul {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            height: 33px;
            padding: 10px 0;
            margin: 0;
            background-color: #fff;
            font-size: 1.2rem;
            line-height: 1;
            text-align: center;

            &:first-child {
              position: sticky;
              left: 0;
            }
          }
        }

        .row:nth-of-type(even) {
          li {
            background-color: #e5e5e5;
          }
        }
      }

      .row-title {
        .title {
          width: 14.5%;
        }
      }

      .row-list {
        .row {
          li {
            width: 14.5%;
          }
        }
      }

      &.col-4 {
        .row-title {
          .title {
            width: 25%;
          }
        }

        .row-list {
          .row {
            li {
              width: 25%;
            }
          }
        }
      }

      &.col-5 {
        .row-title {
          .title {
            width: 20%;
          }
        }

        .row-list {
          .row {
            li {
              width: 20%;
            }
          }
        }
      }

      &.col-6 {
        .row-title {
          .title {
            width: 16.6%;
          }
        }

        .row-list {
          .row {
            li {
              width: 16.6%;
            }
          }
        }
      }

    }

    .size-chart-shoe {
      .size-list {
        .row-title .title {
          width: 33.33%;
        }

        .row-list .row ul li {
          width: 33.33%;
        }
      }
    }

    .size-chart-jumpsuit {
      .size-list {
        .row-title .title {
          width: 17%;
        }

        .row-list .row ul li {
          width: 17%;
        }

      }
    }

    .size-model {
      max-width: 100%;
      text-align: center;
    }
  }

  .tips {
    margin: 6px 0;
    color: #999;
    line-height: 1.4em;
  }
}

#color-chart {
  .color-chart {
    .slc-box {
      padding: 0 15px;
    }

    .slc {
      width: 100%;
      height: 50px;
      border-color: #111;
      background: url(#{$img-path}global/icon_arrow-bottom.png) no-repeat 97% center;
      background-size: 14px 9px;
      text-transform: capitalize;
      font-size: 14px;
      // option {
      // }
    }

    ul {
      margin: 15px auto;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    li {
      padding: 0 15px;
      display: inline-block;
      height: 108px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2rem;
    }

    .empty-li {
      width: 94px;
      display: block;
      height: 0;
    }

    .color {
      height: 64px;
      width: 64px;
      display: block;
      margin-bottom: 6px;
      border-radius: 50%;
    }

    .show-table {
      height: 14px;
      width: 64px;
    }


  }

  .notice {
    padding: 10px 10px 40px;

    a {
      color: #1a0dab;
      text-decoration: underline;
    }
  }
}

#pro-img {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  transition: height .3s;

  .modal-dialog {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .btn-close {
    position: absolute;
    right: -50px;
    top: -50px;
    flex: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100px;
    height: 100px;
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #999;
    background-color: rgba(255, 255, 255, .5);
    z-index: 10;

    i {
      display: block;
      width: 20px;
      height: 20px;
      background: url(#{$img-path}global/ico_close-modal@3x.png) no-repeat center center;
      background-size: contain;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;

    // define var.
    $thumb_height: 100px;

    .img-slider-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      height: calc(100% - #{$thumb_height});
    }

    #img-slider {
      position: relative;
      width: 100%;
      margin: 0;
      background-color: #fff;
      border: none;
      box-shadow: none;
      // img {
      //   //width:100%;
      // }
    }

    #img-slider-thumb {
      padding: 10px 10px;
      width: 100%;
      height: #{$thumb_height};
      background-color: #f5f5f5;

      .swiper-slide {
        height: 80px;

        img {
          height: 100%;
          opacity: .8;
          transition: all .3s;
        }
      }

      .swiper-slide-thumb-active {
        img {
          opacity: 1;
          box-shadow: 2px 3px 5px rgba(0, 0, 0, .4);
        }
      }
    }

  }
}

.match-list-box {
  margin-top: 15px;

  h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    //height: 280px;
    padding-left: 20px;
    margin-top: 19px;

    .item {
      flex: none;
      margin-right: 20px;

      &:last-of-type {
        width: 1px;
        margin-right: 0;
      }

      .img-wrap {
        width: 90px;
        height: 144px;

        img {
          width: 90px;
          height: 144px;
        }
      }

      .mp-price {
        display: flex;
        align-items: flex-start;
        margin-top: 5px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #555;

        .price-symbol {
          font-size: 12px;
          color: #666;
          font-weight: normal;
        }
      }

      .mp-add-to-cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        width: 90%;
        height: 30px;
        padding: 0 14px;
        background-color: #fff;
        border: 1px solid #999;

        .text {
          font-size: 14px;
          line-height: 19px;
        }

        .icon-s {
          background-position: -50px 0;
          opacity: .5;
        }
      }
    }
  }
}

.recent-list-box {
  h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    text-transform: uppercase;
  }

  .prod-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    //height: 280px;
    padding-left: 20px;
    padding-right: 0;
    margin-top: 19px;
  }

  .prod-item {
    flex: none;
    width: 130px;
    padding: 0;
    margin-right: 20px;

    &.empty {
      width: 1px;
      margin-right: 0;
    }

    .prod-img-wrap {
      width: 130px;
      height: 208px;

      .prod-img {
        width: 100%;
        aspect-ratio: 130/208;
      }
    }

    .prod-price {
      .price-value {
        font-weight: normal;
      }
    }
  }
}


