.p-sign {
  //padding-top: $bodyPaddingTop;

  .new-user-tips1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 28px;
    border-radius: 28px;
    background-color: #faf0d5;
    margin: 20px auto;
    font-size: 12px;
    color: #f39800;
    .em {
      display: inline-block;
      margin: 0 3px;
      font-weight: bold;
    }
  }

  .sign-tab{
    margin-top: 21px;
    padding: 0 15px;
    font-family: $font-sans-serif;
    .top{
      width: 50%;
      height: 35px;
      text-align: center;
      padding: 9px 0;
      font-size:1.4rem;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 20px;
      color: $color-gray2;
    }
    .cur {
      color: $color-black;
      border-bottom: 2px solid $color-black;
    }
    .hides{
      display: none;
    }
    p{
      margin-top: -12px;
      margin-bottom: 10px;
      display: none;
    }
    .is-error {
      p{
        display: block;
      }
    }
    .ipt{
      width: 100%;
      height: 50px;
      margin-bottom: 15px;
      font-size:1.4rem;
      border: 1px solid $color-gray3;
      color: $color-black;
      &:focus{
        border-color: $color-black;
      }
    }
    .sign-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
      margin-top: 15px;
      margin-bottom: 20px;
      color: #fff;
    }
    .forgot {
      display: block;
      width: 100%;
      text-align: center;
      font-size:1.4rem;
      text-decoration-line: underline;
      text-decoration-color:#e5e5e5;
      margin-bottom: 32px;
    }
    .line {
      position: relative;
      margin-bottom: 30px;
      span{
        position: absolute;
        text-align: center;
        font-size: 1.4rem;
        width: 66px;
        color: $color-gray2;
        left: 50%;
        transform: translate(-50%, -58%);
        background-color: #fff;
      }
    }

    .fb-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.4rem;
      background-color: $color-main;
      background-color: #4f68a8;
      color: #fff;
      .icon-fb{
        display: inline-block;
        width: 13px;
        height: 25px;
        background: url(#{$img-path}global/icon_facebook.png) no-repeat;
        vertical-align: middle;
      }
    }
    .google-btn {
      margin-top: 15px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.4rem;
      background-color: $color-main;
      background-color: #d7422d;
      color: #fff;
      .icon-google{
        display: inline-block;
        width: 20px;
        height: 25px;
        background: url(#{$img-path}global/icon_google.png) no-repeat;
        vertical-align: middle;
      }
    }
    .signup-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
      margin-top: 15px;
      margin-bottom: 20px;
      color: #fff;
    }
  }
}