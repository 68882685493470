.p-sces {
  //padding-top: $bodyPaddingTop;
  .sces-a {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    .icon {
      height: 86px;
      width: 86px;
      background-color: #46ae65;
      margin:0 auto;
      border-radius: 50%;
      padding: 24px;
      i {
        margin:auto;
        width: 40px;
        height:40px;
        vertical-align:middle;
        background-position: -150px -250px;
      }
    }
    h2 {
      letter-spacing: 2px;
      font-size: 1.8rem;
      margin-top: 10px;
      font-family:$font-serif;
    }

    &.status-pending {
      .icon {
        background: transparent url('../images/payment/img_pending.png');
        background-size: contain;
        border-radius: 0;
        i {
          display: none;
        }
      }
      h2 {
        width: 70%;
        margin: 10px auto;
        font-family: $font-sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: bold;
        line-height: 21px;
      }
    }

  }
  .sces-b {
    height: 100%;
    width: 100%;
    background-color: $color-black;
    padding: 15px 15px 15px 15px;
    font-family: $font-sans-serif;
    .sces-ba {
      background-color: #fff;
      width: 100%;
      height: 100%;
      padding: 19px 15px 0 15px;
      margin-bottom: 15px;
      position: relative;
      h2 {
        text-align: center;
        font-size: 1.2rem;
      }
      h2:last-of-type {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top:16px;
      }
      .detail-box {
        margin-top: 20px;
        padding-bottom: 30px;
        li {
          line-height: 24px;
        }
        span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span:nth-child(even){
          text-align: right;
          font-size: 1.4rem;
          width:66%;
        }
        span:nth-child(odd){
          color: $color-gray2;
          font-size: 1.2rem;
          width:32%;
        }
        .service-email{
          color: #2969d1;
        }
        .total-money {
          color: #c65951;
          font-size:1.8rem !important;
        }
        i {
          border-top: dashed 1px #d3d3d3;
          height: 1px;
          width: 100%;
          display: block;
          text-align: center;
          margin: 21px 0 21px 0;
        }
      }
      .tips {
        font-size: 12px;
        color: #999999;
      }
      .sawtooth {
        display: block;
        background: url(#{$img-path}global/icon_sawtooth.png) $color-black repeat;
        background-size:8px 5px;
        height: 5px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    .email-btn  {
      width: 100%;
      color: #fff;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: #573939;
      border: solid 2px $color-main;
    }
  }

  .sces-c{
    height: 100%;
    width: 100%;
    padding: 15px 14px 13px 14px;
    .article-box {
      margin-bottom: 15px;
      p {
        line-height: 1.71;
        font-family: $font-sans-serif;
        font-size: 1.4rem;
        color: $color-gray2;
      }
      a {
        color: #2969d1;
      }
    }

    .order-btn  {
      appearance: none;
      width: 100%;
      color: #fff;
      line-height: 50px;
      font-size: 1.6rem;
      background-color: $color-main;
      border: 0;
    }
  }

  .join-refer {
    padding:30px 14px;
    img {
      width: 100%;
    }
  }
}
