//index css

.p-idx {
  img {
    width:100%;
  }

  .top-slide {
    position: relative;
    width: 100%;
    margin: 0;
    border: none;
    box-shadow: none;
    aspect-ratio: 390/260;
    .wrap {
        display: block;
    }
    a {
      display: block;
    }
    img {
      width: 100%;
      aspect-ratio: 390/260;
    }
  }

  .swiper-pagination {
    z-index: 1;
  }


  .main-category {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 6% 15px;
    .item {
      display: block;
      width: 27.5%;
      margin-bottom: 15px;
      .c-img {
        display: block;
        width: 100%;
        border-radius: 50%;
        aspect-ratio: 1/1;
      }
      .c-name {
        overflow: hidden;
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .recommend-category {
    .r-item {
      position: relative;
      margin-bottom: 30px;
      padding: 0 10px;
      a {
        display: block;
      }
      .r-title {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .c-top {
        width: 100%;
        aspect-ratio: 370/246;
        margin-bottom: 6px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .r-item-slide {
        position: relative;
        .swiper-slide {
          display: flex;
          justify-content: space-between;
          a {
            width: 49.2%;
          }
          img {
            width: 100%;
            aspect-ratio: 182/264;
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: auto;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 50px;
        background-color: rgba(0,0,0,.5);
        background-image: none;
        .icon-s {
          width: 9px;
          height: 14px;
          background-position: -300px -200px;
        }
        &.swiper-button-disabled {
          pointer-events: auto;
        }
      }
      .swiper-button-prev {
        left: 0;
        border-radius: 0 50px 50px 0;
        .icon-s {
          margin-right: 8px;
          transform:rotate(-180deg);
        }
      }
      .swiper-button-next {
        right: 0;
        border-radius: 50px 0 0 50px;
        .icon-s {
          margin-left: 8px;
          background-position: -300px -200px;
        }
      }
    }
  }

  .middle-slide-wrap {
    width: 100%;
    .middle-slide {
      position: relative;
      width: 100%;
      margin: 0;
      border: none;
      box-shadow: none;
      aspect-ratio: 390/126;
      .wrap {
        display: block;
      }
      a {
        display: block;
      }
      img {
        aspect-ratio: 390/126;
      }
    }

    .swiper-pagination {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      //margin-top: 10px;
      >span {
        display: block;
        margin: 10px 5px 0;
      }
    }
  }

  .recommend-product {
    margin-top: 20px;
    .rp-header {
      position: sticky;
      top: 38px;
      z-index: 1;
      background-color: #fff;
      &.under-header {
        top: 92px;
        transition: top .3s;
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 33px;
        border-bottom: 1px solid #d8d8d8;
        .item {
          position: relative;
          padding: 10px 0;
          font-size: 16px;
          font-weight: bold;
          color: #86868a;
          text-transform: capitalize;
          &.on {
            color: #111;
            &:after {
              content: '';
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #111;
            }
          }
        }
      }

      .sublist {
        display: none;
        overflow-y: auto;
        padding: 10px;
        .subitem {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          padding: 3px 16px;
          margin-right: 16px;
          border-radius: 21px;
          border: solid 1px #ddd;
          font-size: 14px;
          text-transform: capitalize;
          white-space: nowrap;
          &.on {
            background-color: #dc7b78;
            border-color: #dc7b78;
            color: #fff;
          }
        }
        &.on {
          display: flex;
        }
      }
    }

    .r-prod-list {
      display: none;
      margin-top: 10px;
      &.on {
        display: block;
      }

      .btn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 32px;
        margin: 20px auto;
        border: solid 1px #111;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }

  .wedding-101 {
    display: block;
    margin-top: 30px;
    img {
      width: 100%;
      aspect-ratio: 390/113;
    }
  }


  .advantage-slide-wrap {
    padding: 30px 10px;

    .advantage-slide {
      width: 100%;
    }
    .item {
      overflow: hidden;
      padding: 30px;
      background-color: #F8F6F0;
      .icon {
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 auto;
      }
      .s-i-title {
        margin-top: 15px;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        text-align: center;
      }
      .desc {
        margin-top: 15px;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
      }
      a,.link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 182px;
        height: 32px;
        margin: 20px auto;
        border: 1px solid #111;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .swiper-pagination {
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      >span {
        display: block;
        margin: 0 5px;
      }
    }
  }

  .site_description {

    padding: 15px;
    color: #111;

    h2 {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
    }

    h3 {
      margin-top: 20px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5;
      text-decoration: underline;
    }

    //.desc {
    //}
    p {
      font-size: 12px;
      line-height: 1.5;
      color: #555;
      a {
        color: #4781e6;
        text-decoration: underline;
      }
    }
  }


}
