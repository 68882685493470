.p-article{
  .article-box{
    font:1.4rem/1em $font-sans-serif;
    padding:0 4.5%;
    .title{
      font-size: 1.8rem;
      font-family: 'arial';
      font-weight:bold;
      margin: 18px 0 10px;
    }
    .subtitle{
      font-size: 1.4rem;
      font-family: 'arial';
      font-weight:bold;
      margin: 15px 0 0;
      line-height: 30px;
    }

    p,li{
      margin-top: 5px;
      margin-bottom: 5px;
      line-height: 2rem;
      text-align: left;
    }

    a {
      color: #2969d1;
    }

    .table{
      width: 100%;
      tr{
        td {
          border:1px solid #111;
          padding: 8px 15px;
          line-height: 24px;
        }
        &.th {
          background-color: #ecd4d3;
          font-weight: bold;
        }
      }
    }
  }
  .classh2  {
    font-family: $font-serif;
    font-size: 1.8rem;
    text-align: center;
    color: $color-black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
  }
  .col-box {
    ul {
      margin-bottom: 350px;
      word-break: break-all;
      a{
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 1.4rem;
        height: 50px;
        line-height: 50px;
        font-family: $font-sans-serif;
        border-bottom: 1px solid #e5e5e5;
        position:relative;
        .p-arrow {
          margin-top: 20px;
          background-position: -338px -250px;
          position:absolute;
          display: inline-block;
          right: 10px;
        }
      }
      & > li > a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .blog-item ul {
    margin-bottom: auto;
  }
}

/** The common styles for articles*/
.p-article {
  .article-box {
    font-size: 1.4rem;
    color: #111;

    h2 {
      margin: 22px 0 10px;
      font-size: 1.8rem;
      font-weight: bold;
    }

    h3 {
      margin: 10px 0 0;
      font-size: 1.5rem;
      line-height: 26px;
      font-weight: bold;
    }

    h4 {
      margin: 5px 0;
      font-size: 1.4rem;
      font-weight: bold;
    }

    p {
      margin: 6px 0 0;
      font-size: 1.4rem;
      line-height: 22px;
    }

    blockquote,
    blockquote p {
      margin: 0;
      padding: 0;
      border: none;
      font-size: 1.4rem;
      line-height: 28px;
      color: #999;
    }
  }
}